import React, { useState, useEffect } from "react";
import { ContainerBox } from "../styles";

import UploadSped from "../components/upload/index";
import UploadingSped from "../components/uploading";
import tools from "../../../../../utils/icos/tools.png";


import percent from "../../../../../templates/utils/percent";
import { useProjetos } from "../../../../../hooks/projetos";
import { BsThreeDots } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";
import {
  HiUpload,
  HiIdentification,
  HiOutlineReceiptTax,
} from "react-icons/hi";

import { MdDateRange, MdChecklist } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { IoDocumentTextOutline, IoBusiness, IoOptions } from "react-icons/io5";
import { Tag } from "../../../../login/styles";

import { useUpload } from "../../../../../hooks/upload";

export default function UploadController({
  mode,
  setMode,
  loading,
  setLoading,
  switcher,
}) {
  const { projeto, infos } = useProjetos();
  const [dash, setDash] = useState("upload");
  const { tasks, setTasks, setStatus, status } = useUpload();

  const states = [
    {
      state: "upload",
      component: (
        <UploadSped
          setStatus={setStatus}
          tasks={tasks}
          setTasks={setTasks}
          mode={mode}
          setMode={setMode}
          switcher={switcher}
        />
      ),
    },
    {
      state: "uploading",
      component: (
        <UploadingSped
          setStatus={setStatus}
          status={status}
          tasks={tasks}
          setTasks={setTasks}
          mode={mode}
          setMode={setMode}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    setStatus("uploading");
  }, [projeto]);

  function GenTags(tag) {
    function openTools() {
      document.getElementById("tools").style.display = "flex";
    }
    function startTag() {
      const tag = document.getElementById("tag-info");
      tag.display = "flex";
      setTimeout(() => {
        tag.style.opacity = "1";
      }, 500);
    }

    function endTag() {
      const tag = document.getElementById("tag-info");
      setTimeout(() => {
        tag.style.opacity = "0";
        tag.display = "none";
      }, 500);
    }

    function showInfo(num, msg) {
      const tag = document.getElementById("tag-info");
      num = num * 2;
      if (String(msg).length < 30) {
        num = num + 3;
      }
      tag.style.display = "flex";
      tag.style.transform = `translateX(${num * 1.7}em) translateY(2em)`;

      document.getElementById("tag-text").innerHTML = msg;
    }

    function hideInfo() {
      const tag = document.getElementById("tag-info");
      tag.style.display = "none";
    }

    function formatBytes(a, b = 2) {
      if (!+a) return "0 Bytes";
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      }`;
    }

    function openSpeds() {
      document.getElementById('speds-tool').style.display = 'flex'
    }

    var tags = [
      {
        ico: <HiIdentification />,
        value: infos.cnpjs,
        msg: "Quantidade de CNPJ's que compõem as informalçoes levantadas pelo sistema a partir dos arquivos inseridos.",
      },
      {
        ico: <IoDocumentTextOutline />,
        value: infos.speds.toLocaleString(),
        msg: "SPEDS encontrados pelo sistema a partir dos arquivos inseridos.",
        func: openSpeds
      },
      {
        ico: <HiOutlineReceiptTax />,
        value: infos.nfes.toLocaleString(),
        msg: "Notas fiscais encontradas pelo sistema a partir dos arquivos inseridos.",
      },
      {
        ico: <FaDatabase />,
        value: formatBytes(infos.size),
        msg: "Quantidade de memória consumida no banco de dados pelo projeto.",
      },
      {
        ico: <MdDateRange />,
        value: `${new Date(infos.inicio)
          .toLocaleDateString()
          .substring(3)} ~ ${new Date(infos.fim)
          .toLocaleDateString()
          .substring(3)}`,
        msg: "Periodo análisado.",
      },
      {
        ico: <BiLoaderAlt />,
        value: `${
          isNaN(percent(infos.check, infos.nfes))
            ? "0"
            : percent(infos.check, infos.nfes) < 100
            ? percent(infos.check, infos.nfes)
            : 100
        } %`,
        msg: "Percentual de conformidade entre os documentos entregues.",
      },
    ];

    return (
      <>
        <div className="btns" onMouseOver={startTag} onMouseOut={endTag}>
          {tags.map((obj) => (
            <div
              className={obj.value !== undefined ? "btn" : "tools"}
              onMouseOver={() => showInfo(tags.indexOf(obj), obj.msg)}
              onMouseOut={hideInfo}
              onClick={obj.func !== undefined ? obj.func : () => {}}
            >
              {obj.ico}
              {obj.value !== undefined && <p>{obj.value}</p>}
            </div>
          ))}
        </div>
      </>
    );
  }

  function checkClass(str) {
    if (dash === str) {
      return "ativ";
    }
  }

  return (
    <ContainerBox id="uploadx" className="upload align">
      <div className="fix">
        {dash === "upload" &&
          states.find((obj) => obj.state === status).component}
      </div>

      <>
        {infos !== undefined && (
          <div className="top">
            <div
              className="tag"
              id="tag-info"
              style={{
                display: "none",
                transform: `translateX(${10 * 1.7}em) translateY(-3.2em)`,
              }}
            >
              <p id="tag-text">
                Quantidade de CNPJS que compoêm os arquivos inseridos no sistema
              </p>
            </div>
            <div
              className="tag"
              id="tag-info"
              style={{
                transform: `translateX(${1 * 1.7}em) translateY(-3.2em)`,
              }}
            >
              <p id="tag-text">Configurações do módulo</p>
            </div>
            <GenTags />
          </div>
        )}
        {infos !== undefined && (
          <>
            <div className="bottom">
              <div className="content">
                <div className="header">
                  <p>NOTAS FISCAIS</p>
                  <p>
                    {infos.nfes !== null ? infos.nfes.toLocaleString() : 0} /{" "}
                    {infos.check !== null ? infos.check.toLocaleString() : 0}
                  </p>
                </div>
                <div className="progress">
                  <div
                    className="load-bar"
                    style={{ width: `${percent(infos.check, infos.nfes)}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </ContainerBox>
  );
}

import styled from 'styled-components'

const colors = require('./utils/colors')

export const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 249, 249, 1) 45%,
    rgba(235, 235, 235, 1) 100%
  );
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: overlay;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  -webkit-box-sizing: border-box;

  * {
    margin: 0;
  }

  .blur {
    -webkit-filter: blur(3px);
  }
`;



export const Display = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(119, 119, 119, 0.02);
  }

  ::-webkit-scrollbar-track:hover {
    background: rgba(133, 133, 133, 0.034);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 20, 0.363);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(14, 14, 14, 0.76);
  }
`

export const Block = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255, 0.04);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
  scroll-snap-align: start;

  justify-content: center;

  &.a {
    background-color: rgb(255, 255, 255, 0.07);
  }

  &.b {
  }

  h1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10em;
  }
`

export const Header = styled.div`
  width: 100%;
  height: 13em;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.01);
  h1 {
    font-size: 2.5em;
    width: 60%;
  }
`
export const Side = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 13em);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const Card = styled.div`
  width: 23em;
  height: 30em;
  margin: 0 2em;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.01);

  border-radius: 5px;
`

export const CardHeader = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;

  svg {
    font-size: 3em;
    margin: 0 0.6em;

    opacity: 0.7;
    transform: translateY(0.4em);
  }
`

export const CardTitle = styled.div`
  width: 100%;
  height: max-content;
  display: flex;

  h2 {
    width: calc(100%);
    margin-left: 1em;
    font-size: 1.8em;
    margin-top: 1em;
    margin-bottom: 20px;
  }

  #arrow {
    font-size: 5em;
    opacity: 0.5;
    color: rgba(0, 200, 100);
    right: 0;
    transform: translateX(0.25em);
  }
`

export const CardText = styled.div`
  width: 100%;
  height: calc(100% - 13em);
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    width: 83%;
    transform: translateX(-0.5%);
    opacity: 0.7;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 30px;
  }
`

export const CardLine = styled.div`
  width: 100%;
  height: 0.2em;
  background: rgb(132, 0, 255);
  background: linear-gradient(
    90deg,
    rgba(132, 0, 255, 1) 0%,
    rgba(94, 0, 112, 1) 60%,
    rgba(29, 0, 20, 0.773546918767507) 100%
  );
  opacity: 0.4;
`


import axios from "axios";
import React, { useState, useEffect } from "react";

import {
  ContainerBox,
  UploadBox,
  UploadingBox,
  UploadInfo,
  Reset,
} from "../styles";

import LoadPanel from "./load_panel";

import { useProjetos } from "../../../../../hooks/projetos";
import { useAuth } from "../../../../../hooks/auth";

import filesize from "filesize";

import reduzirStr from "../../../../../utils/reduzirStr";
import Loading from "../../../../../utils/loading";
import { BsCheck } from "react-icons/bs";
import { VscLibrary } from "react-icons/vsc";
import { useUpload } from "../../../../../hooks/upload";
import alerta from "../../../../../utils/alerta";

const uri = process.env.REACT_APP_API_URI;

export default function UploadingSped({
  tasks,
  setTasks,
  setMode,
  loading,
  setLoading,
}) {
  const { getEmpresas, setEmpresas, empresas } = useProjetos();
  const [update, setUpdate] = useState(false);
  const { status, setStatus } = useUpload();

  const { projeto } = useProjetos();
  const { user } = useAuth();

  useEffect(() => {
    if (status === "uploading") {
      setLoading(true);
      setUpdate(true);
    }
  }, [status]);

  function subs(str) {
    if (str.includes("\\")) {
      return str.substring(str.lastIndexOf("\\") + 1);
    }

    if (str.includes("/")) {
      return str.substring(str.lastIndexOf("/") + 1);
    }
  }

  useEffect(() => {
    (async () => {
      function finalize() {
        check = false;
        setUpdate(false);
        setStatus("upload");
        setTasks([]);
      }

      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      if (update === true && projeto !== undefined) {
        var check = true;

        while (check) {
          await axios.get(`${uri}/user/${user}`).then(async (res) => {
            if (res.data === projeto) {
              await axios.get(`${uri}/tasks/${projeto}`).then(async (res) => {
                if (res.status === 200) {
                  await getEmpresas();
                  if (res.data.length > 0) {
                    setTasks(res.data.filter((obj) => obj.tipo !== "icms"));
                  }
                  await timer(2000);
                  setLoading(false);

                  if (
                    res.data.filter((obj) => obj.status === "doing").length ===
                      0 &&
                    res.data.filter((obj) => obj.status === "todo").length === 0
                  ) {
                    finalize();
                  }

                  if (
                    res.data.length === 0 ||
                    res.data.filter((obj) => obj.status === "done").length ===
                      res.data.length
                  ) {
                    finalize();
                  } else {
                    setUpdate(true);
                  }
                }
              });
            } else {
              finalize();
            }
          });
        }
      }
    })();
  }, [update, projeto, status]);

  function percent(total, num) {
    var perc = total / 100;

    return parseFloat(num / perc).toFixed(2);
  }

  function findStage(task, type) {
    const stages = [
      {
        tipo: "upload_nfes",
        text: {
          base: "Enviando arquivos para o servidor",
          success: "Arquivos enviados para o servidor",
          todo: "Enviar arquivos para o servidor",
        },
      },
      {
        tipo: "google_xml",
        text: {
          base: "Procurando XML's na conta Gmail",
          success: "Arquivos encontrados",
          todo: "Procurar XML's na conta Gmail",
        },
      },
      {
        tipo: "download_google",
        text: {
          base: "Fazendo download dos XML's encontrados",
          success: "Arquivos enviados para o servidor",
          todo: "Baixar XML's encontrados",
        },
      },
      {
        tipo: "unzip",
        text: {
          base: "Extraindo arquivos",
          success: "Arquivos extraídos",
          todo: "Extrair arquivos comprimidos",
        },
      },
      {
        tipo: "count_xml",
        text: {
          base: "Procurando XMLS",
          success: "XMLS encontrados",
          todo: "Procurar XMLS nos arquivos enviados",
        },
      },
      {
        tipo: "transf_xml",
        text: {
          base: "Extraindo NFes",
          success: "NFes transferidas",
          todo: "Transferir XMLS para o banco de dados",
        },
      },

      {
        tipo: "checklist",
        text: {
          base: "Processando NFe's",
          success: "NFe's processadas",
          todo: "Processas NFe's enviadas",
        },
      },
      {
        tipo: "upload",
        text: {
          base: "Enviando arquivos para o servidor",
          success: "Arquivos enviados para o servidor",
          todo: "Enviar NFe's para o servidor",
        },
      },
      {
        tipo: "process_speds",
        text: {
          base: "Processando SPED's enviados",
          success: "SPED's processados",
          todo: "Processar SPED's enviados",
        },
      },

      {
        tipo: "process_empresas",
        text: {
          base: "Cadastrando empresas",
          success: "Empresas cadastradas",
          todo: "Cadastrar empresas",
        },
      },

      {
        tipo: "compress_speds",
        text: {
          base: "Comprimindo SPED's para download",
          success: "Arquivos prontos para download",
          todo: "Preparar SPED's para download",
        },
      },
      {
        tipo: "transf_to_compress",
        text: {
          base: "Transferindo NFe's filtradas para compressão",
          success: "NFe's transferidas",
          todo: "Separar NFe's filtradas para download",
        },
      },

      {
        tipo: "compress_nfes",
        text: {
          base: "Comprimindo notas fiscas para download",
          success: "Arquivos prontos para download",
          todo: "Preparar NFe's para download",
        },
      },

      {
        tipo: "process_nfes",
        text: {
          base: "Processando notas fiscais",
          success: "Notas fiscais processadas",
          todo: "Processar informações das notas fiscais",
        },
      },
    ];

    const stage = stages.find((obj) => obj.tipo === task.tipo);

    if (task.status === "doing") {
      return stage.text.base;
    }

    if (task.status === "todo") {
      return stage.text.todo;
    }

    return stage.text.success;
  }

  function handleReset() {
    function start() {
      axios.get(`${uri}/projetos/reset/${projeto}`).then((res) => {
        if (res.status === 200) {
          alerta(res.data.msg, false, "success");
        }
      });
    }

    alerta(
      "Tem certeza que deseja cancelar as tarefas atuais?",
      true,
      "err",
      start
    );
  }

  function returnTask(task) {
    return (
      <UploadingBox
        className={`${task.status} ${task.tipo}`}
        key={tasks.indexOf(task)}
      >
        {task.tipo === 'upload_nfes' && (
          <p id="task-prog" style={{display: 'none'}}>{task.status}</p>
        )}
        <div className="content">
          <div className="thumb">
            {task.status !== "done" ? (
              task.tipo === "unzip" ? (
                task.logs.some((obj) => obj.status === "false") ||
                task.logs.length === 0 ? (
                  <Loading />
                ) : (
                  <BsCheck color="green" />
                )
              ) : (
                <Loading />
              )
            ) : (
              <BsCheck color="green" />
            )}
          </div>
          <div className="load">
            <div
              className={`load-tag ${task.status}
                    ${["unzip", "count_xml"].includes(task.tipo) && "done"}`}
            >
              <p>{findStage(task)}</p>
              {task.tipo === "upload_nfes" ? (
                <p className="number">
                  {task.status !== "done"
                    ? `${filesize(task.progress)} / ${filesize(task.goal)}`
                    : filesize(task.goal)}
                </p>
              ) : (
                <>
                  {!["unzip", "count_xml"].includes(task.tipo) && (
                    <>
                      
                      <p className="number">
                        {task.status !== "done"
                          ? `${task.progress.toLocaleString()} / ${task.goal.toLocaleString()}`
                          : task.goal.toLocaleString()}
                      </p>
                    </>
                  )}
                  {["count_xml"].includes(task.tipo) && (
                    <p className="number">{task.progress.toLocaleString()}</p>
                  )}

                  {task.tipo === "unzip" && task.status !== "done" && (
                    <p className="number">
                      {task.logs.filter((obj) => obj.status === "done").length}{" "}
                      / {task.logs.length}
                    </p>
                  )}
                </>
              )}
            </div>
            {!["unzip", "count_xml"].includes(task.tipo) && (
              <div className="load-box">
                <div className={`load-bar ${task.status} `}>
                  <div
                    className="progress"
                    style={{
                      width: `${percent(task.goal, task.progress)}%`,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {task.tipo === "google_xml" &&
          task.logs !== undefined &&
          task.logs.length > 0 && (
            <div className="logs">
              {task.logs.map((obj) => (
                <div className={`log`}>
                  <div className="thumb">
                    <VscLibrary />
                  </div>
                  <div className="infos">
                    <div className="text">
                      <p>{obj}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

        {task.tipo === "unzip" &&
          task.logs !== undefined &&
          task.logs.length > 0 && (
            <div className="logs">
              {task.logs.map(
                (obj) =>
                  obj.progress !== 100 && (
                    <div className={`log ${obj.status}`}>
                      <div className="thumb">
                        <VscLibrary />
                      </div>
                      <div className="infos">
                        <div className="text">
                          <p>{subs(obj.file)}</p>
                          <p>{obj.progress} %</p>
                        </div>
                        <div className="load">
                          <div
                            className="progress"
                            style={{ width: `${obj.progress}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
      </UploadingBox>
    );
  }

  return (
    <UploadBox className="light">
      {loading ? (
        <LoadPanel />
      ) : projeto !== undefined ? (
        <>
          <div className="displayz">
            {tasks !== undefined && (
              <>
                {tasks
                  .filter((obj) => obj.status === "done")
                  .map((task) => returnTask(task))}
                {tasks
                  .filter((obj) => obj.status === "doing")
                  .map((task) => returnTask(task))}
                {tasks
                  .filter((obj) => obj.status === "todo")
                  .map((task) => returnTask(task))}
              </>
            )}
          </div>
          <Reset>
            <div className="btn" onClick={handleReset}>
              <p>Cancelar</p>
            </div>
          </Reset>
        </>
      ) : (
        <LoadPanel />
      )}
    </UploadBox>
  );
}

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const UploadContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function UploadProvider({ children }) {
  const [mode, setMode] = useState("speds");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("uploading");
  const [switcher, setSwitch] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [atualize, setAtualize] = useState(false)

  return (
    <UploadContext.Provider
      value={{
        mode,
        setMode,
        loading,
        setLoading,
        status,
        setStatus,
        switcher,
        setSwitch,
        tasks,
        setTasks,
        atualize,
        setAtualize
      }}
    >
      {children}
    </UploadContext.Provider>
  );
}

export function useUpload() {
  const context = useContext(UploadContext);
  const {
    mode,
    setMode,
    loading,
    setLoading,
    status,
    setStatus,
    switcher,
    setSwitch,
    tasks,
    setTasks,
    atualize,
    setAtualize
  } = context;
  return {
    mode,
    setMode,
    loading,
    setLoading,
    status,
    setStatus,
    switcher,
    setSwitch,
    tasks,
    setTasks,
    atualize,
    setAtualize
  };
}

import React, { useState, useEffect } from "react";

import UploadController from "./controllers/upload";
import UploadedSpeds from "./components/uploaded/";
import Filtro from "../filter";
import useSpeds from "../../../../hooks/speds";
import { useProjetos } from "../../../../hooks/projetos";
import { Switch } from "./styles";
import timer from "../../../../utils/timer";

import {
  HiUpload,
  HiIdentification,
  HiOutlineReceiptTax,
} from "react-icons/hi";

import { MdDateRange, MdChecklist } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { IoDocumentTextOutline, IoBusiness, IoOptions } from "react-icons/io5";
import { useUpload } from "../../../../hooks/upload";

export default function UploadDash() {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const projetosHook = useProjetos();
  const { projeto, empresas } = projetosHook;
  const uri = `/speds/${projeto}`;
  const hook = useSpeds(uri, "POST", query, page, useProjetos());
  const {
    mode,
    setMode,
    loading,
    setLoading,
    status,
    setStatus,
    switcher,
    setSwitch,
  } = useUpload();

  function switchMode(str) {
    if (status !== "uploading") {
      setSwitch(true);
      setStatus("uploading");
      setMode(str);
      setLoading(true);
    }
  }

  function checkClass(str) {
    if (str === mode || str === status) {
      return "ativ";
    }
  }

  return (
    <>
      {empresas.length > 0 && (
        <Switch className={checkClass("uploading")}>
          <div
            className={`box init ${checkClass("nfes")}`}
            onClick={() => switchMode("nfes")}
          >
            <HiOutlineReceiptTax />
            <p>NFe's</p>
          </div>
          <div className="switch-box left">
            <div className="switch">
              <div
                className={`circle ${checkClass("speds")}`}
                id="switch-circle"
              ></div>
            </div>
          </div>
          <div
            className={`box end ${checkClass("speds")}`}
            onClick={() => switchMode("speds")}
          >
            <IoDocumentTextOutline />
            <p>SPED</p>
          </div>
        </Switch>
      )}
      <Filtro setQuery={setQuery} hook={hook} />
      <UploadController
        mode={mode}
        setMode={setMode}
        loading={loading}
        setLoading={setLoading}
        status={status}
        setStatus={setStatus}
        switcher={switcher}
      />
      <UploadedSpeds
        hook={hook}
        setPage={setPage}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
}

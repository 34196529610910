import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 26;
  color: black;
  font-size: 1.1em;
`;

export const Block = styled.div`
  width: 23em;
  height: max-content;
  display: flex;
  border-radius: 10px;
  background-color: rgba(240, 240, 240);
  overflow: hidden;
  flex-direction: column;

  .header {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    background-color: rgba(250, 250, 250);
    justify-content: space-between;

    .closx {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      background-color: white;

      &:hover {
        svg {
          opacity: 0.8;
        }
      }

      svg {
        font-size: 0.8em;
        opacity: 0.5;
      }
    }

    .text {
      width: calc(100% - 2em);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-weight: 600;
        font-size: .9em;
        opacity: .8;
        transform: translateX(-1em);
      }
    }
  }
`;

export const Content =styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  


  .texto {
    margin: 25px 0 !important;
    width: 90%;
    font-size: .8em;
  }
`

export const Dates = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1px !important;

  .date {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    opacity: 0.8;
    

    &:hover {
      opacity: 1;
    }

    &.ativ {
      opacity: 1;
      transform: translateY(1px);
      border: 1px solid rgba(0, 230, 250, 0.3);
    }

    &.left {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .ico {
      width: 3em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        margin-right: 0.8em !important;
        opacity: 0.7;
      }
    }

    .text {
      width: calc(100% - 5em);
      height: 100%;
      transform: translateY(-0.2em);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      user-select: none;

      p {
        font-size: 0.8em;
        line-height: 18px;
        opacity: 0.8;

        &.data {
          font-size: 0.8em;
          font-weight: 700;
        }
      }
    }
  }
`;

export const DatePicker = styled.div`
  width: 100%;
  height: max-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 230, 250, 0.3);

  .dates {
    width: 100%;
    display: flex;
    height: max-content;
    flex-wrap: wrap;

    .date {
      width: calc(100% / 6 - 2px);
      display: flex;
      height: 2em;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.05);
      justify-content: center;
      align-items: center;
      background-color: white;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.ativ {
        opacity: 1;
      }

      &.year {
        height: 1.8em;
      }

      p {
        font-size: 0.8em;
      }
    }
  }
`;

export const Empresas = styled.div`
  width: 100%;
  height: 18em;
  display: flex;
  flex-direction: column;

  &.ativ {
    height: 24em;
  }

  .search {
    width: 100%;
    height: 2em;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;

    .ico {
      width: 2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      svg {
        opacity: 0.5;
      }
    }

    input {
      width: calc(100% - 3.5em);
      height: 100%;
      background-color: white;
      font-size: 0.7em;
      color: black;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    overflow: overlay;

    .empresa {
      width: 100%;
      height: 3em;
      display: flex;
      background-color: white;
      margin-top: 1px !important;
      opacity: 0.5;
      cursor: pointer;

      &.ativ {
        opacity: 0.8;
        border-top: 1px solid rgba(0, 200, 250, 0.2);
      }

      &:hover {
        opacity: 1;
      }

      .ico {
        width: 2.5em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          font-size: 0.8em;
        }
      }

      .text {
        width: calc(100% - 3em);
        height: 3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.5em;
        p {
          font-size: 0.8em;
          line-height: 18px;

          &.cnpj {
            font-size: 0.7em;
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const Send = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  background-color: white;
  .btn-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      font-size: 1.2em;
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(15, 15, 15);
      color: white;
      cursor: pointer;
      border-radius: 5px;

      &.ativ {
        display: none;
      }

      p {
        font-size: 0.7em;
        font-weight: 600;
        padding: 5px 30px !important;
      }
    }

    .btn-google {
      width: max-content;
      height: 1.7em;
      font-size: 1.2em;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 5px;
      background-color: rgba(0, 0, 255, 0.6);
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
      .thumb {
        width: 1.6em;
        height: 1.6em;
        background-color: white;

        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:1px solid rgba(0, 0, 255, 0.6);

        img {
          width: 1em;
        }
      }

      .text {
        width: calc(100% - 1.65em);
      }

      p {
        font-size: 0.65em;
        font-weight: 600;
        color: white;
        padding: 5px 30px !important;
      }
    }
  }

  .info-box {
    width: calc(100% - 10em);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;



import React, { useState, useEffect } from 'react'
import {
  Tela,
  Card,
  Menu,
  TopBtn,
  Btn,
  Thumb,
  Display,
  Content,
  ContentDisplay,
  Divider,
  ThumbPhoto,
  EditBtn
} from './styles'

import { VscInfo } from 'react-icons/vsc'
import { MdArrowBackIos } from 'react-icons/md'

export default function Settings() {
  function close() {
    var sets = document.getElementById('settings')
    var cards = document.getElementById('settingsCard')
    var content = document.getElementById('content')
    
    cards.classList.remove('ativ')
    content.style.display = 'none'
    content.classList.remove('ativ')

    setTimeout(() => {
      sets.style.opacity = '0'
      sets.classList.remove('ativ')
    }, 100)
  }

  function open() {
    var sets = document.getElementById('settings')
    var cards = document.getElementById('settingsCard')
    var btn = document.getElementById('settingsBtn')
    var content = document.getElementById('content')

    btn.addEventListener('click', () => {
      sets.classList.add('ativ')
      content.style.display = 'flex'
      setTimeout(() => {
        sets.style.opacity = '1'
        cards.classList.add('ativ')
      }, 100)

      setTimeout(() => {
        content.classList.add('ativ')
      }, 200)
    })
  }

  useEffect(() => {
    open()
  }, [])

  function MenuDiv() {
    return (
      <Menu>
        <TopBtn>
          <div className="close-btn" onClick={close}>
            <MdArrowBackIos />
          </div>
          <p>AUTOMEC@HOTMAIL.COM</p>
        </TopBtn>
        <Btn className="ativ">
          <Thumb>
            <p>A</p>
          </Thumb>
          <p>Minha conta</p>
        </Btn>
      </Menu>
    )
  }

  function Profile() {
    return (
      <Display>
        <Content>
          <h2>Conta</h2>
          <Divider />
          <p className="info padd">
            <VscInfo />
            Nesta seção você pode alterar todas as informações da sua conta.
          </p>
          <p className="title">Foto</p>
          <ThumbPhoto>
            <h1>A</h1>
          </ThumbPhoto>
          <EditBtn>
            <p>Alterar foto</p>
          </EditBtn>
          <Divider className="padd" />
          <p className="bottom title">informações</p>
          <p className="info small">Email</p>
          <p className="bottom">henrique@fhsouza.com.br</p>
          <p className="info small">Nome</p>
          <input placeholder="Henrique Esteves de Souza"></input>
          <Divider className="padd" />
          <p className="title">Senha</p>
          <p className="info">
            Você pode alterar sua senha por aqui se precisar
          </p>
          <EditBtn>
            <p>Alterar senha</p>
          </EditBtn>
          <Divider className="padd" />
        </Content>
      </Display>
    )
  }

  return (
    <Tela id="settings" >
      <Card id="settingsCard">
        <ContentDisplay id="content">
          <MenuDiv />
          <Profile />
        </ContentDisplay>
      </Card>
    </Tela>
  )
}

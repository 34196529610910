import styled from "styled-components";

export const Menu = styled.div`
  width: 100%;
  height: 2em;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;

  .btn {
    width: max-content;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    padding: 0px 50px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
    margin-right: 2px !important;
    opacity: 0.5;

    &:hover,
    &.ativ {
      opacity: 1;
      border-bottom: 1px solid rgba(0, 200, 250, 0.3);
    }
    svg {
      font-size: 0.7em;
      margin-right: 10px !important;
    }
    p {
      font-size: 0.8em;
    }
  }
`;

export const Grupos = styled.div`
  width: 31em;
  height: 90%;
  margin-left: 1em !important;
  display: flex;
  background-color: rgba(245, 245, 245, 1);
  flex-direction: column;

  .header {
    width: 100%;
    height: 1.8em;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    p {
      margin-left: 15px !important;
      font-size: 0.8em;
      font-weight: 400;
      opacity: 0.9;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 1.8em);
    display: flex;
    flex-direction: column;
    overflow: overlay;

    .item-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: max-content;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      .item {
        width: 100%;
        height: 4.5em;
        display: flex;
        align-items: center;
        margin: 0.2em 0 !important;
        background-color: white;

 


        .thumb {
          width: 4em;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.03);

          svg {
            font-size: 1.8em;
            opacity: 0.3;
          }
        }

        .infos {
          width: calc(100% - 4em);
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-top: .5em;

          .bar {
            width: 100%;
            
            min-height: .3em;
            max-height: 2em;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              margin: 0 10px !important;
              margin-right: 2em !important;
              font-size: 0.8em;
              font-weight: 400;
              opacity: 0.9;

              &.info {
                margin: 0 !important;
                font-size: .7em;
                padding: 5px 0;
                opacity: .5;
              }
            }

            .load {
              margin-left: 10px !important;
              margin-right: 1.5em !important;
              width: 100%;
              height: 0.3em;
              background-color: rgba(0, 0, 0, 0.06);
              display: flex;
              flex-direction: column;

              .progress {
                height: 100%;
                background-color: red;
                opacity: .7;
              }
            }
          }
        }
      }

      .infos-item {
        width: 100%;
        height: 1.5em;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);

        .info {
          width: max-content;
          padding: 0 5px !important;
          border-right: 1px solid rgba(0, 0, 0, 0.03);
          display: flex;
          align-items: center;
          background-color: white;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }

          svg {
            font-size: 0.8em;
            opacity: 0.5;
            margin-right: 5px;
          }

          p {
            margin-right: 5px !important;
            font-size: 0.8em;
            font-weight: 400;
            opacity: 0.6;
          }
        }
      }
    }
  }
`;


export const Cards = styled.div`
  width: 100%;
  height: 8em;
  font-size: 1em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  align-items: center;
  overflow: hidden;
  overflow-x: overlay;

  .card {
    width: max-content;
    min-width: 15em;
    height: 5.5em;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0em 1em !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);

    .content {
      width: 100%;
      height: calc(100% - 1.5em);
      display: flex;
      align-items: center;

      h1 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 1.4em;
        margin-left: 0.8em !important;
        margin-right: 2em !important;
      }
    }
  }
`;

export const Tabela = styled.div`
  width: 100%;
  height: calc(100% - 8em);
  display: flex;
  flex-direction: column;

  .table-head {
    width: 100%;
    height: 2em;
    display: flex;
    align-items: center;
    background-color: white;
    p {
      opacity: 0.5;
      font-size: 0.8em !important;
    }

    .thumb {
      width: 2.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      svg {
        font-size: 0.8em;
      }
    }
  }
  .item {
    width: 10em;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: nowrap;

    &.cnpj {
      width: 20em;
    }

    p {
      font-size: 0.85em;
      margin-left: 1em !important;
      white-space: nowrap;
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 1.8em);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;

    .items {
      width: 100%;
      min-height: 2.5em;
      display: flex;
      background-color: rgba(255, 255, 255);
      align-items: center;
      opacity: 0.6;
      margin-top: 1px !important;
      cursor: pointer;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
        height: calc(2.5em - 1px);
        border-bottom: 1px solid rgba(0, 200, 255, 0.3);
      }

      .thumb {
        width: 2.5em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          font-size: 0.8em;
        }
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import {
  ContainerBox,
  UploadBox,
  Upload,
  UploadSend,
  UploadBtn,
  UploadList,
  UploadDisplay,
  UploadItem,
  UploadInfo,
} from "../../styles";

import LoadPanel from "../load_panel";

import { CgLoadbarDoc, CgDanger } from "react-icons/cg";
import { VscTrash } from "react-icons/vsc";
import { AiOutlineCheckCircle } from "react-icons/ai";

import axios from "axios";

import filesize from "filesize";
import Dropzone from "react-dropzone";
import { CircularProgressbar } from "react-circular-progressbar";
import { uniqueId } from "lodash";

import reduzir from "../../../../../../utils/reduzirStr";

import { useProjetos } from "../../../../../../hooks/projetos";
import { useUpload } from "../../../../../../hooks/upload";
import alerta from "../../../../../../utils/alerta";

const uri = process.env.REACT_APP_API_URI;

export default function UploadSped({ setMode, mode, switcher}) {
  const { projeto, empresas, setTask, projeto_infos } = useProjetos();
  const [arquivos, setArquivos] = useState([]);
  const [sending, setSend] = useState(false);
  const {status, setStatus} = useUpload()

  useEffect(() => {

    if (empresas.length !== 0 && !switcher) {
      setMode("nfes");
    }
  }, [empresas]);

  useEffect(() => {
    console.log(arquivos);
    if (mode === "nfes") {
      if (arquivos.some((obj) => obj.file.path.includes(".txt"))) {
        console.log(arquivos);
        setMode("nfes");
      }
    }
  }, [arquivos]);


  function handleUpload(files) {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      size: filesize(file.size),
      realSize: file.size,
      progress: 0,
      uploaded: false,
      error: false,
    }));

    setArquivos(arquivos.concat(uploadedFiles));
  }

  function sendFiles() {
    const data = new FormData();
    const url = `${uri}/${mode}/upload/${projeto}`;
    const files = [];
    for (var i = 0; i < arquivos.length; i++) {
      const file = arquivos[i];
      data.append("files", file.file);
      files.push(file.file);
    }

    if (mode === "speds") {
      const task = {
        projeto,
        task: "upload_sped",
        files,
      };
      setStatus("uploading");
      console.log(files)
      axios.post(`${uri}/tasks`, task).then((resp) => {
        alert("uploaded")
        if (resp.status === 200) {
          setStatus("uploading");
        }
        axios.post(url, data).then((res) => {
          console.log(res)
        });
      });
    }

    if (mode === "nfes") {
      const task = {
        projeto,
        task: "upload_nfes",
        files,
        goal: arquivos[0].realSize,
      };
      setStatus("uploading");

      console.log(task);
      axios.post(`${uri}/tasks`, task).then((resp) => {
        if (resp.status === 200) {
          setStatus("uploading");
        }
        axios.post(url, data).then((res) => {
          alert(res.status);
        });
      });
    }
  }

  function handleDelete(file) {
    setArquivos(arquivos.filter((obj) => obj.id !== file.id));
  }

  function checkClass() {
    if (arquivos.length === 0) {
      return "ativo";
    }

    return "";
  }

  useEffect(() => {
    if(projeto_infos === null) {
      alerta('Nenhum projeto selecionado. Vincule um projeto a esta conta no painela administrativo.', false, 'err')
    }
  }, [projeto_infos])

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return (
        <div className="upload-box">
          <div className="plus">+</div>
          <div className="upload-text">
            <h2>{mode === "speds" ? "Carregar SPEDS" : "Carregar NFes"}</h2>
            <p className="info">Arraste os arquivos que deseja enviar</p>
          </div>
        </div>
      );
    }

    if (isDragReject) {
      return (
        <div className="upload-box">
          <CgDanger className="plus red" />
          <div className="upload-text">
            <h2>Não suportado</h2>
            <p className="info">
              Os arquivos devem ser do tipo{" "}
              {mode === "speds" ? ".txt" : ".rar ou .zip"}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="upload-box">
        <AiOutlineCheckCircle className="plus green" />
        <div className="upload-text">
          <h2>Solte os arquivos</h2>
          <p className="info">Arquivos suportados</p>
        </div>
      </div>
    );
  }

  return projeto_infos !== null ? (
    <Dropzone
      accept={`${mode === "speds" ? "text/plain" : "application/*"}`}
      onDropAccepted={handleUpload}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <ContainerBox className="upload align">
          <UploadBox>
            <Upload
              id="dropzone"
              {...getRootProps()}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              className={checkClass()}
            >
              <input
                {...getInputProps()}
                type="file"
                style={{ display: "none" }}
                id="drop-input"
              />
              {renderDragMessage(isDragActive, isDragReject)}
            </Upload>

            <UploadList className={checkClass()}>
              <UploadDisplay>
                {arquivos.length > 0 &&
                  arquivos.map((sped) => (
                    <UploadItem>
                      <div className="thumb">
                        <CgLoadbarDoc />
                      </div>
                      <div className="text">
                        <p className="nome">{reduzir(sped.name, 25)}</p>
                        <p className="info">{sped.size}</p>
                      </div>

                      {sending ? (
                        <div className="thumb btn">
                          <CircularProgressbar
                            styles={{
                              root: { width: 24 },
                              path: {
                                stroke: "#7159c1",
                                transition: "stroke-dashoffset 0.5s ease 0s",
                              },
                              trail: {
                                stroke: "rgb(200, 200, 200)",
                              },
                            }}
                            strokeWidth={10}
                            value={sped.progress}
                          />
                        </div>
                      ) : (
                        <div
                          className="thumb btn red"
                          onClick={() => handleDelete(sped)}
                        >
                          <VscTrash />
                        </div>
                      )}
                    </UploadItem>
                  ))}
              </UploadDisplay>
            </UploadList>

            {arquivos.length !== 0 && (
              <UploadSend>
                <UploadBtn id="upload-send" onClick={sendFiles}>
                  <p>Enviar arquivos</p>
                </UploadBtn>
              </UploadSend>
            )}
          </UploadBox>
        </ContainerBox>
      )}
    </Dropzone>
  ) : (
    <UploadBox>
      <LoadPanel style={{ width: "100%" }} />
    </UploadBox>
  );
}

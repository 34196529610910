import React, { useEffect, useState } from "react";
import axios from "axios";

import percent from "../../../../../utils/percent";

import { Container, Block, Header } from "./styles";

import { MdClose } from "react-icons/md";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

import { HiReceiptTax, HiOutlineReceiptTax } from "react-icons/hi";
import { useProjetos } from "../../../../../hooks/projetos";

function format(num) {
  if(num !== null) {
    var first = String(num).slice(0, 1);
    var second = String(num).slice(1, 2);

    if (String(num).split(".")[0].length === 9) {
      return `${String(num).slice(0, 3)},${String(num).slice(3, 4)} mi`;
    }

    if (String(num).split(".")[0].length === 8) {
      return `${String(num).slice(0, 2)},${String(num).slice(2, 3)} mi`;
    }

    if (String(num).split(".")[0].length === 7) {
      return `${String(num).slice(0, 1)},${String(num).slice(1, 2)} mi`;
    }

    if (String(num).split(".")[0].length > 9) {
      return `${first},${second} bi`;
    }

    return num.toLocaleString();
  }
}

const uri = process.env.REACT_APP_API_URI;

export default function Produtos() {
  const { projeto, empresas } = useProjetos();
  const [data, setData] = useState();
  const [item, setItem] = useState();
  function handleClose() {
    document.getElementById("prods").style.display = "none";
  }

  useEffect(() => {
    if (projeto !== undefined) {
      axios
        .post(`${uri}/reports/${projeto}`, { report: "codigos" })
        .then((res) => {
          if (res.status === 200) {
            if (res.data !== undefined && res.data !== null) {
              setData(res.data);
              if (res.data.cods !== undefined) {
                
              }
            }
          }
        });
    }
  }, [projeto]);

  return (
    <Container id="prods">
      <Header>
        <div className="btn" onClick={handleClose}>
          <MdClose />
        </div>
      </Header>
      <Block>
        <div className="table">
          {data !== undefined &&
            data !== null &&
            data.cods.map((obj) => (
              <div className="item" onClick={() => setItem(obj)}>
                <div className="infos">
                  <div className="load-infos">
                    <div className="text">
                      <p>
                        {obj.infos.cod} - {obj.desc}
                      </p>
                      <p>R$ {format(obj.lucro)}</p>
                    </div>
                    <div className="load">
                      <div className="load-bar">
                        <div
                          className="progress"
                          style={{
                            width: `${percent(obj.lucro, data.infos.lucro)}%`,
                            backgroundColor: obj.color,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="prod-infos">
                    <div className="info">
                      <BsArrowDown />
                      <p>R$ {format(obj.infos.entradas.vlr)}</p>
                    </div>
                    <div className="info">
                      <BsArrowUp />

                      <p>R$ {format(obj.infos.saidas.vlr)}</p>
                    </div>
                    <div className="info">
                      <HiReceiptTax />
                      <p>R$ {format(obj.infos.entradas.icms)}</p>
                    </div>
                    <div className="info">
                      <HiOutlineReceiptTax />
                      <p>R$ {format(obj.infos.entradas.st)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="infos">
          {item === undefined ? (
            <div className="item big">
              <div className="thumb"></div>
              {data !== undefined && data !== null && (
                <div className="infos">
                  <div className="load-infos">
                    <div className="text">
                      <p>{data.infos.nome}</p>
                      <p>R$ {format(data.infos.lucro)}</p>
                    </div>
                    <div className="load">
                      <div className="load-bar">
                        <div className="progress"></div>
                      </div>
                    </div>
                  </div>
                  <div className="prod-infos">
                    <div className="info">
                      <BsArrowUp />
                      <p>R$ {format(data.infos.compras)}</p>
                    </div>
                    <div className="info">
                      <BsArrowDown />
                      <p>R$ {format(data.infos.vendas)}</p>
                    </div>
                    <div className="info">
                      <HiReceiptTax />
                      <p>R$ {format(data.infos.icms)}</p>
                    </div>
                    <div className="info">
                      <HiOutlineReceiptTax />
                      <p>R$ {format(data.infos.st)}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="head mini">
                <p>Informações sobre o item</p>
              </div>
              <div className="item  light">
                <div className="infos">
                  <div className="load-infos">
                    <div className="text">
                      <p>
                        {item.infos.cod} - {item.desc}
                      </p>
                      <p>R$ {format(item.lucro)}</p>
                    </div>
                    <div className="load">
                      <div className="load-bar">
                        <div
                          className="progress"
                          style={{
                            width: `${percent(item.lucro, data.infos.lucro)}%`,
                            backgroundColor: item.color,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="prod-infos">
                    <div className="info">
                      <BsArrowDown />
                      <p>R$ {format(item.infos.entradas.vlr)}</p>
                    </div>
                    <div className="info">
                      <BsArrowUp />

                      <p>R$ {format(item.infos.saidas.vlr)}</p>
                    </div>
                    <div className="info">
                      <HiReceiptTax />
                      <p>R$ {format(item.infos.entradas.icms)}</p>
                    </div>
                    <div className="info">
                      <HiOutlineReceiptTax />
                      <p>R$ {format(item.infos.entradas.st)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="head mini">
                <div className="head-item">
                  <p>Compra</p>
                </div>
                <div className="head-item">
                  <p>Venda</p>
                </div>
                <div className="head-item">
                  <p>Estoque</p>
                </div>
              </div>

              <div className="head">
                <div className="head-item">
                  <p>{item.infos.entradas.qtd}</p>
                </div>
                <div className="head-item">
                  <p>{item.infos.saidas.qtd}</p>
                </div>
                <div className="head-item">
                  <p>{item.infos.entradas.qtd - item.infos.saidas.qtd}</p>
                </div>
              </div>

              <div className="head mini">
                <div className="head-item">
                  <p>Compra</p>
                </div>
                <div className="head-item">
                  <p>Venda</p>
                </div>
                <div className="head-item">
                  <p>BC_ST</p>
                </div>
              </div>

              <div className="head">
                <div className="head-item">
                  <p>R$ {format(item.infos.entradas.vlr_unit)}</p>
                </div>
                <div className="head-item">
                  <p>R$ {format(item.infos.saidas.vlr_unit)}</p>
                </div>
                <div className="head-item">
                  <p>R$ {format(item.infos.entradas.bc_st.media)}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </Block>
    </Container>
  );
}

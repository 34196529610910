import { InfoBlock } from "./styles";

import { BsInfoCircle } from "react-icons/bs";

export default function Infos({msg}) {
  return (
    <InfoBlock>
      {" "}
      <div className="btn-info">
        <BsInfoCircle />
      </div>
      <div className="info-text">
        <p>{msg}</p>
      </div>
    </InfoBlock>
  );
}

import React from "react";
import { useEffect } from "react";
import axios from "axios";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import App from "./App";
import { useProjetos } from "./hooks/projetos";
import { useParams } from "react-router-dom";
import { useAuth } from "./hooks/auth";
import { useState } from "react";

const uri = process.env.REACT_APP_API_URI;

export default function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<App />} />
        <Route path="/:projeto" element={<Child />} />
      </Routes>
    </Router>
  );
}

function Child() {
  const { projeto } = useParams();
  const [check, setCheck] = useState(false);
  const user = localStorage.getItem("user_id");

  async function setProj(obj) {
    axios.post(`${uri}/user/${user}`, { projeto: obj }).then((res) => {
      if (res.status === 200) {
        window.location.href = "/";
      }
    });
  }

  useEffect(() => {
    setProj(projeto);
  }, []);
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 250, 250);
  display: none;

  .btn {
    width: 2em;
    height: 2em;
    background-color: black;
    // margin: 2em !important;
  }
`

export const Form = styled.form`
  z-index: 3;
`

export const Header = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  position: fixed;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  top: 0;
  background-color: white;
`

export const Block = styled.div`
  width: 25em;
  height: 28em;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background-color: rgba(250, 250, 250);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  z-index: 3;

  .btn-google {
    width: max-content;
    height: 1.7em;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    opacity: 0.8;
    margin-bottom: 1em !important;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      opacity: 1;
    }
    .thumb {
      width: 1.6em;
      height: 1.6em;
      background-color: white;
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1em;
      }
    }

    .text {
      width: calc(100% - 1.65em);
    }

    p {
      font-size: 0.65em;
      font-weight: 600;
      width: 100%;
      color: black;
      padding: 5px 30px !important;
    }
  }

  .content {
    width: 85%;
    height: 90%;
    display: flex;
    flex-direction: column;

    .Toastify__close-button {
      width: 2em !important;
      height: 2em !important;
      margin-top: 0em !important;
    }

    .Toastify__toast-icon {
      margin-right: 1em;
    }

    h1 {
      color: black;
      font-family: 'Roboto', sans-serif;
      font-weight: bolder;
      font-size: 1.5em;
    }

    h3 {
      color: black;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.8em;
      font-weight: 500;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 0.6em;
      align-items: center;
    }

    .forgot-buttons {
      display: flex;
      flex-direction: column;
      gap: 0.6em;
      align-items: center;
    }

    .forgot-buttons button {
      margin-top: 2em;
      width: 12em;
      height: 3em;
      background-color: black;
      border: none;
      border-radius: 5px;
      color: white;
    }

    .all-inputs {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 1em;

      input {
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.03);
        padding: 0 !important;
        padding: 0 5px !important;
      }
    }

    .input-code {
      width: 3em;
      height: 3.5em;
      background: #d9d9d9;
      border-radius: 5px;
      text-align: center;
    }

    .newpassword-input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .newpassword-input input {
      width: 100%;
      color: black;
      padding: 0 !important;
      padding-left: 3em !important;
      height: 3em;
      background-color: rgba(0, 0, 0, 0.03);
      border: 1px solid rgba(0, 0, 0, 0.03);
      border-left: none;
      padding-left: 0 !important;
    }

    h5 {
      font-family: 'Roboto';
      color: #000000;
      font-size: 0.8em;
      text-align: center;
      font-weight: 500;
      margin-top: 1em;
    }

    span {
      font-weight: bolder;
    }

    .btn-forgot {
      border-radius: 5px;
      background-color: black;
      cursor: pointer;
      user-select: none;
      color: white;
      width: 10em;
      height: 2.8em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3em;
    }

    .btn-code {
      border-radius: 5px;
      background-color: black;
      cursor: pointer;
      user-select: none;
      color: white;
      width: 10em;
      height: 2.8em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1em;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .progress-bar {
      margin-top: 4em;
      display: flex;
      justify-content: center;
      gap: 0.2em;

      .return-page,
      .actual-page,
      .next-page {
        width: 3.8em;
        height: 1em;
        border-radius: 50px;
      }

      .black {
        background-color: black;
      }

      .gray {
        background-color: #d9d9d9;
      }
    }

    h4 {
      color: black;
      font-size: 1em;
      font-family: 'Montserrat', Arial, Helvetica, sans-serif;
      font-weight: 500;
      text-align: center;
    }

    .header {
      width: 100%;
      height: 5em;
      display: flex;
      align-items: center;

      img {
        height: 2.7em;
        transform: translateY(-0.7em);
      }
    }

    .inputs {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding-bottom: 0.5em !important;
      &.forgot {
        padding: 0 !important;
      }
      .input-box {
        display: flex;
        height: max-content;
        align-items: center;
        padding: 10px 0 !important;
        svg {
          color: black;
          font-size: 1.3em;

          opacity: 0.3;
        }

        &.end {
          justify-content: flex-end;
          svg {
            cursor: pointer;
            opacity: 0.2;
            font-size: 1.1em;
          }

          #pass-anti-eye {
            display: none;
          }
        }
      }

      input {
        width: 100%;
        color: black;
        padding: 0 !important;
        padding-left: 3em !important;
        height: 3em;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-left: none;
        padding-left: 0 !important;
      }

      .thumb {
        width: 3em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-right: none;

        &.end {
          cursor: pointer;
          border-left: none;
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        svg {
          font-size: 1em;
        }
      }
    }

    .login-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      p {
        color: black;
        font-size: 0.9em;
        font-weight: 500;
      }

      .forgot-btn {
        cursor: pointer;
        opacity: 0.5;
        margin-bottom: 1em;
      }
    }

    .btn {
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 50px !important;
      border-radius: 5px;
      background-color: black;
      cursor: pointer;
      user-select: none;
      color: white;

      @media (max-width: 900px) {
        font-size: 0.9em;
      }

      h2 {
        font-size: 1.2em;
      }
    }
  }
`

import { useEffect, useState, useRef, useCallback } from "react";

import {
  ContainerBox,
  Uploaded,
  UploadedItem,
  UploadedMenu,
} from "../../styles";

import { FaFilter } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { IoMdTrash } from "react-icons/io";
import { IoCubeOutline, IoChevronBackOutline } from "react-icons/io5";

import { useProjetos } from "../../../../../../hooks/projetos";

import percent from "../../../../../../utils/percent";
import reduzir from "../../../../../../utils/reduzirStr";

import alerta from "../../../../../../utils/alerta.js";

import ContentLoader, { Facebook } from "react-content-loader";

export default function UploadedSpeds({ hook, setPage, query, setQuery }) {
  const [view, setView] = useState("empresas");
  const { getSped, setStatus, status, empresas, projeto } = useProjetos();

  const { speds, loading, hasMore, setSpeds } = hook;
  const observer = useRef();

  const last_element = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if(empresas.length === 1) {
      setView('speds')
    }
  }, [empresas]);

  function LoadItem() {
    return (
      <UploadedItem>
        <div className="content">
          <ContentLoader viewBox="0 0 380 70">
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
            <rect x="80" y="17" rx="4" ry="4" width="340" height="10" />
            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            <rect x="80" y="80" rx="3" ry="3" width="380" height="10" />
          </ContentLoader>{" "}
          <ContentLoader
            style={{ width: "130%", transform: "translateY(.5em)" }}
            viewBox="100 0 380 90"
          >
            {/* Only SVG shapes */}
            <rect x="80" y="10" rx="4" ry="4" width="330" height="10" />
            <rect x="80" y="28" rx="4" ry="4" width="200" height="10" />
            <rect x="80" y="46" rx="3" ry="3" width="290" height="10" />
            <rect x="80" y="64" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>
        </div>
      </UploadedItem>
    );
  }
  function LoadPanel() {
    var x = 0;
    var result = [];

    while (x !== 12) {
      result.push(x);
      x++;
    }

    return (
      <Uploaded>
        <div className="contentx">
          {result.map((obj) => (
            <LoadItem key={result.indexOf(obj)} />
          ))}
          <div
            className="limit"
            style={{ height: `calc(100% - ${result.length * 4.1}em)` }}
          ></div>
        </div>
      </Uploaded>
    );
  }

  function CardView(file, ref, array) {
    const meses = [
      { nome: "Janeiro", value: 0, abrv: "JAN" },
      { nome: "Fevereiro", value: 1, abrv: "FEV" },
      { nome: "Março", value: 2, abrv: "MAR" },
      { nome: "Abril", value: 3, abrv: "ABR" },
      { nome: "Maio", value: 4, abrv: "MAI" },
      { nome: "Junho", value: 5, abrv: "JUN" },
      { nome: "Julho", value: 6, abrv: "JUL" },
      { nome: "Agosto", value: 7, abrv: "AGO" },
      { nome: "Setembro", value: 8, abrv: "SET" },
      { nome: "Outubro", value: 9, abrv: "OUT" },
      { nome: "Novembro", value: 10, abrv: "NOV" },
      { nome: "Dezembro", value: 11, abrv: "DEZ" },
    ];
    function handleChoose(file, ide) {
      setSpeds([]);
      setQuery({
        date: { start: query.date.start, end: query.date.end },
        cnpj: file.cnpj,
      });
      setView("speds");
    }

    return (
      file !== undefined && (
        <UploadedItem id={file._id} ref={ref} key={array.indexOf(file)}>
          {view === "empresas" && (
            <div
              className="content"
              id={`content-${array.indexOf(file)}`}
              onClick={() =>
                handleChoose(file, `content-${array.indexOf(file)}`)
              }
            >
              <div className="header">
                <div className="thumb">
                  <IoCubeOutline color={file.color} />
                </div>
                <div className="name">
                  <p className="texto">{reduzir(file.nome, 18)}</p>
                  <p className="cnpj">{file.cnpj}</p>
                </div>
              </div>
              <div className="infos">
                <div className="box">
                  <p className="info">SPEDS</p>
                  <p>{file.speds}</p>
                </div>
                <div className="box">
                  <p className="info">FALTANTES</p>
                  <p>{file.faltantes}</p>
                </div>
              </div>
              <div className="infos">
                <div className="nfe-tags">
                  <p className="info">NOTAS FISCAIS</p>
                  <p className="info">
                    {file.nfes - file.faltantes} / {file.nfes}
                  </p>
                </div>
              </div>
              <div className="infos">
                <div className="progress-bar">
                  <div
                    style={{
                      width: `${percent(
                        file.nfes - file.faltantes,
                        file.nfes
                      )}%`,
                    }}
                    className="content"
                  ></div>
                </div>
              </div>
            </div>
          )}
          {view === "speds" && (
            <div className="content" onClick={() => getSped(file)}>
              <div className="header">
                <div className="thumb">
                  <IoCubeOutline color={file.color} />
                </div>
                <div className="name">
                  <p className="texto">
                    EFD - {meses.find((obj) => obj.value === file.mes).nome}{" "}
                    {file.ano}
                  </p>
                </div>
              </div>
              <div className="infos">
                <div className="box">
                  <p className="info">LINHAS</p>
                  <p>{file.linhas}</p>
                </div>
                <div className="box">
                  <p className="info">FALTANTES</p>
                  <p>{file.faltantes}</p>
                </div>
              </div>
              <div className="infos">
                <div className="nfe-tags">
                  <p className="info">NOTAS FISCAIS</p>
                  <p className="info">
                    {file.nfes - file.faltantes} / {file.nfes}
                  </p>
                </div>
              </div>
              <div className="infos">
                <div className="progress-bar">
                  <div
                    style={{
                      width: `${percent(
                        file.nfes - file.faltantes,
                        file.nfes
                      )}%`,
                    }}
                    className="content"
                  ></div>
                </div>
              </div>
            </div>
          )}
        </UploadedItem>
      )
    );
  }

  function filter(str) {
    if (status !== "filter") {
      setStatus("filter");
    } else {
      setStatus();
    }

    var filter = document.getElementById("filter");
    filter.classList.toggle("ativ");
  }

  function Menu() {
    const { deleteSpeds } = useProjetos();
    function handleDelete() {
      alerta("Tem certeza que deseja deletar todos os SPEDS?", true, "none");
    }

    function handleBack() {
      setView("empresas");
    }

    return (
      <UploadedMenu>
        <div className="btns">
          {view === "speds" && (
            <>
              <div className="btn" onClick={handleBack}>
                <IoChevronBackOutline />
              </div>
            </>
          )}
        </div>

        {view === "speds" && (
          <div className="tools">
            <div className="btn " onClick={() => filter("open")}>
              <FaFilter className="fa" />
            </div>
          </div>
        )}
      </UploadedMenu>
    );
  }

  function Fill(number) {
    var value;
    if (number) {
      if(number < 4) {
        value = 20
      }
      if (number < 3) {
        value = 24;
      }
      if (number > 3 && number < 7) {
        value = 20;
      }
      if (number > 7) {
        value = 17;
      }

      if (number > 9) {
        value = 2;
      }
    }
    return (
      <div
        style={{
          width: "100%",
          height: `${value}em`,
        }}
      ></div>
    );
  }

  return (
    <ContainerBox id="fix" className="bigger uploaded">
      <Menu />
      {loading ? (
        <LoadPanel />
      ) : view === "speds" ? (
        <>
          {speds.length !== 0 && projeto !== undefined ? (
            <Uploaded>
              <div className="contentx">
                {speds.map((sped, index) => {
                  if (speds.length === index + 1) {
                    return <div>{CardView(sped, last_element, speds)}</div>;
                  } else {
                    return CardView(sped, null, speds);
                  }
                })}
                {Fill(speds.length)}
              </div>
            </Uploaded>
          ) : (
            <LoadPanel />
          )}
        </>
      ) : (
        view === "empresas" && (
          <>
            {empresas.length !== 0 && projeto !== undefined ? (
              <Uploaded>
                <div className="contentx">
                  {empresas.map((empresa, index) => {
                    return CardView(empresa, null, empresas);
                  })}
                  {Fill(empresas.length)}
                </div>
              </Uploaded>
            ) : (
              <LoadPanel />
            )}
          </>
        )
      )}
    </ContainerBox>
  );
}

export default function alerta(msg, bool, status, func) {
  document.getElementById("alert").style.display = "flex";
  document.getElementById("alert-text").innerHTML = msg;

  if (status === "success") {
    document.getElementById("alert-sucess").style.display = "flex";
    document.getElementById("alert-err").style.display = "none";
  } else {
    document.getElementById("alert-sucess").style.display = "none";
    document.getElementById("alert-err").style.display = "success";
  }

  if (bool) {
    document.getElementById("bool").style.display = "flex";
    document.getElementById("not-bool").style.display = "none";

    var sim = document.getElementById("alert-sim");
    var nao = document.getElementById("alert-nao");

    sim.addEventListener(
      "click",
      () => {
        if (func) func();
        sim.removeEventListener("click", func);
        document.getElementById("dash").classList.remove("blur");
        document.getElementById("alert").style.display = "none";
      },
      { once: true }
    );

    nao.addEventListener("click", () => {
      sim.removeEventListener("click", func);
      document.getElementById("dash").classList.remove("blur");
      document.getElementById("alert").style.display = "none";
    });
  } else {
    document.getElementById("bool").style.display = "none";
    document.getElementById("not-bool").style.display = "flex";

    var ok = document.getElementById("alert-ok");

    ok.addEventListener(
      "click",
      () => {
        if (document.getElementById("dash") !== null) {
          document.getElementById("dash").classList.remove("blur");
        }

        document.getElementById("alert").style.display = "none";
        if (func) func();
      },
      { once: true }
    );
  }
}

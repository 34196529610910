import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, Cards, Grafico, InfoBlock } from "../charts/styles";
import { Menu, Grupos, Tabela } from "./styles";
import { IoCubeOutline } from "react-icons/io5";

import {
  MdKeyboardBackspace,
  MdCameraRoll,
  MdChair,
  MdWatch,
} from "react-icons/md";
import {
  BsInfoCircle,
  BsCalendarDate,
  BsArrowDown,
  BsArrowUp,
  BsCurrencyDollar,
} from "react-icons/bs";

import { FaRegIdCard, FaTint, FaRedhat } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";

import {
  HiReceiptTax,
  HiOutlineReceiptTax,
  HiOutlineNewspaper,
} from "react-icons/hi";



import { useProjetos } from "../../../../hooks/projetos";
import reduzir from "../../../../utils/reduzirStr";

import percent from "../../../../utils/percent";

import ContentLoader, { Facebook } from "react-content-loader";

import Infos from "../../../../utils/infos";
import { useICMS } from "../../../../hooks/icms";

const uri = process.env.REACT_APP_API_URI;

export default function ICMSDash() {
  const [data, setData] = useState([]);
  const { projeto } = useProjetos();
  const { openItem } = useICMS();
  const [infos, setInfos] = useState();
  useEffect(() => {
    axios.get(`${uri}/icms/${projeto}`).then((res) => {
      setData(res.data);
    });

    axios.get(`${uri}/icms-infos/${projeto}`).then((res) => {
      setInfos(res.data);
    });
  }, []);

  function LoadPanel() {
    var loads = [];

    var x = 4;
    while (x !== 10) {
      loads.push("a");
      x++;
    }

    function randomInt(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
    return (
      <>
        {" "}
        <Cards>
          {loads.slice(0, 5).map((obj) => (
            <div className="card">
              <div className="head">
                <ContentLoader viewBox="0 0 380 70">
                  <rect
                    x="15"
                    y="32"
                    rx="3"
                    ry="3"
                    width={randomInt(150, 250)}
                    height="15"
                  />
                </ContentLoader>{" "}
              </div>
              <div className="content">
                <ContentLoader viewBox="0 0 380 70">
                  <rect
                    x="15"
                    y="12"
                    rx="3"
                    ry="3"
                    width={randomInt(150, 300)}
                    height="35"
                  />
                </ContentLoader>
              </div>
            </div>
          ))}
        </Cards>
        <Grafico>
          <div className="index">
            <div className="head">
              <ContentLoader viewBox="0 0 380 70">
                <rect
                  x="15"
                  y="31"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 100)}
                  height="10"
                />
              </ContentLoader>{" "}
            </div>
            <div className="display">
              {loads.slice(0, 4).map((obj) => (
                <div className="item">
                  <div className="thumb"></div>
                  <div className="content"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="grafico">
            <div className="head">
              <ContentLoader viewBox="0 0 380 70">
                <rect
                  x="10"
                  y="31"
                  rx="3"
                  ry="3"
                  width={randomInt(40, 100)}
                  height="7"
                />
              </ContentLoader>{" "}
            </div>
            <div className="charts"></div>
          </div>
        </Grafico>
        <Tabela>
          <div className="head table">
            <div className="item cnpj"></div>
            <div className="item "></div>
            <div className="item "></div>
            <div className="item "></div>
            <div className="item "></div>
          </div>
          <div className="display">
            {loads.concat(loads).map((obj) => (
              <div className="items">
                <div className="item cnpj">
                  <div className="thumb"></div>
                </div>
                <div className="item "></div>
                <div className="item "></div>
                <div className="item "></div>
                <div className="item "></div>
              </div>
            ))}
          </div>
        </Tabela>
      </>
    );
  }

  function format(num) {
    if (!isNaN(num)) {
      return parseFloat(num).toLocaleString();
    } else {
      return 0;
    }
  }

  return (
    <Container>
      {data.length !== 0 && infos !== undefined ? (
        <>
          {" "}
          <Cards>
            <div className="card">
              <div className="head">
                <p>Códigos</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>

              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  {format(infos.itens)}
                </h1>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <p>Compras</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>
              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  R$ {format(infos.compra)}
                </h1>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <p>Vendas</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>
              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  R$ {format(infos.venda)}
                </h1>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <p>ICMS Pago</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>
              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  R$ {format(infos.icms)}
                </h1>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <p>ICMS Realizado</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>
              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  R$ {format(infos.icms_realizado)}
                </h1>
              </div>
            </div>
            <div className="card">
              <div className="head">
                <p>Saldo</p>
                <div className="head ico">
                  <div className="head-ico"></div>
                  <Infos />
                </div>
              </div>
              <div className="content">
                <h1 style={{ fontSize: "1.2em", fontWeight: "300" }}>
                  R$ {format(infos.saldo)}
                </h1>
              </div>
            </div>
          </Cards>
          <Tabela>
            <div className="table-head">
              <div className="thumb"></div>

              <div className="item">
                <p>Código</p>
              </div>
              <div className="item cnpj">
                <p>Descrição</p>
              </div>
              <div className="item">
                <p>Qtd compra</p>
              </div>
              <div className="item">
                <p>Valor compra</p>
              </div>
              <div className="item">
                <p>Qtd venda</p>
              </div>
              <div className="item">
                <p>Valor venda</p>
              </div>
              <div className="item">
                <p>ICMS pago</p>
              </div>
              <div className="item">
                <p>ICMS realizado</p>
              </div>
              <div className="item">
                <p>Saldo</p>
              </div>
            </div>
            <div className="display">
              {data.slice(0, 100).map((obj) => (
                <div className="items" onClick={() => openItem(obj)}>
                  <div className="thumb"></div>
                  <div className="item">
                    <p>{obj.cod}</p>
                  </div>
                  <div className="item cnpj">
                    <p>{obj.desc}</p>
                  </div>
                  <div className="item">
                    <p>{obj.entradas_qtd}</p>
                  </div>
                  <div className="item">
                    <p>R$ {format(obj.entradas_vlr)}</p>
                  </div>
                  <div className="item">
                    <p> {obj.saidas_qtd}</p>
                  </div>
                  <div className="item">
                    <p>R$ {format(obj.saidas_vlr)}</p>
                  </div>
                  <div className="item">
                    <p>R$ {format(obj.icms_pago)}</p>
                  </div>
                  <div className="item">
                    <p>R$ {format(obj.icms_realizado)}</p>
                  </div>
                  <div className="item">
                    <p>R$ {format(obj.saldo)} </p>
                  </div>
                  <div></div>
                </div>
              ))}
            </div>
          </Tabela>
        </>
      ) : (
        <LoadPanel />
      )}
    </Container>
  );
}

import styled, { css } from "styled-components";
export const ContainerBox = styled.div`
  width: 45em;
  min-width: 38em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .fix {
    width: 100%;
    height: calc(100% - 4em);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top {
    width: 100%;
    height: 2em;
    font-size: 1.1em;
    display: flex;

    overflow: overlay;
    overflow-y: hidden;

    &.head {
      height: 1.5em;
      cursor: pointer;

      .btns {
        .btn {
          &.ativ {
            opacity: 1;
          }
          p {
            font-size: 0.7em;
          }
        }
      }
    }

    .tag {
      max-width: 20em;
      width: max-content;
      min-width: 10em;
      min-height: 3em;
      display: flex;
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: opacity 1s;
      opacity: 0;
      z-index: 14;

      p {
        font-size: 0.7em;
        padding: 5px 10px;
        line-height: 18px;
        opacity: 0.7;
      }
    }

    .btns {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .btn {
        width: max-content;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.03);
        background-color: rgba(255, 255, 255, 1);
        cursor: pointer;
        border-right: 1px solid rgb(0, 0, 0, 0.1);
        opacity: 0.5;
        transition: all 0.2s;

        svg {
          font-size: 0.8em;
          opacity: 0.5;
          padding: 0 5px !important;
        }

        p {
          font-size: 0.8em;
          white-space: nowrap;
          height: 100%;
          display: flex;
          align-items: center;
          padding-right: 15px !important;
          margin-left: 5px !important;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .tools {
      width: 2.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      opacity: 0.7;
      border-left: 1px solid rgba(0, 0, 0, 0.03);
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 0.8em;
        opacity: 0.3;
      }
    }
  }

  .bottom {
    width: 100%;
    height: 4em;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;

    .content {
      width: 95%;
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        width: 100%;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 0.7em;
          opacity: 0.5;
        }
      }

      .progress {
        width: 100%;
        margin-top: 5px;
        height: 0.3em;
        display: flex;
        background-color: rgba(150, 150, 150, 0.1);
        overflow: hidden;
        border-radius: 2px;

        .load-bar {
          width: 0%;
          background-color: rgba(0, 150, 255, 0.3);
        }
      }
    }
  }

  &.uploaded {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 20em;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
  }

  &.bigger {
    margin-left: 0em !important;
    align-items: flex-start;
    width: calc(100% - 40em);

    @media screen and (max-width: 1600px) {
      width: max-content;
    }
  }

  &.align {
    min-width: 36.6em;
    flex-direction: column;
  }
`;

export const UploadBox = styled.div`
  min-width: 73%;
  max-width: 85%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;

  &.light {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .displayz {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 4em);
    overflow-y: overlay;
  }
`;

export const Reset = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255);
  bottom: 0;
  opacity: .8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .btn {
    width: max-content;
    height: max-content;
    background-color: white;
    border: 1px solid rgba(0, 150, 255, 0.3);
    border-radius: 5px;

    p {
      padding: 3px 45px !important;
      font-size: 0.8em;
      opacity: .8;
    }
  }
`;

export const UploadInfo = styled.div`
  width: 100%;
  height: 25%;
  background-color: white;
  display: flex;
  flex-direction: column;

  .boxes {
    width: 100%;
    height: 4.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    overflow: hidden;
    background-color: white;
    display: flex;

    .box {
      width: calc(100% / 3);
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.03);

      .tag {
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        height: 1.5em;
        display: flex;
        align-items: center;

        p {
          font-size: 0.6em;
          opacity: 0.5;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 2em !important;
        }
      }

      .value {
        width: 100%;
        height: calc(100% - 1.5em);
        display: flex;
        align-items: center;

        h2 {
          margin-left: 1.25em;
          font-weight: 400;
          font-size: 1.2em;

          &.date {
            font-size: 1em;
          }
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    .contentt {
      width: 90%;
      height: 4em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 1em !important;

      .tag {
        width: 100%;
        height: 1.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 0.7em;
          opacity: 0.7;
        }
      }

      .loading-bar {
        width: 100%;
        height: 0.3em;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

const dragActive = css`
  border-color: #78e5d5;

  background-color: rgba(0, 250, 100, 0.03);
`;

const dragReject = css`
  border-color: #e57878;
  background-color: rgba(250, 0, 0, 0.03);
`;

export const Upload = styled.div`
  width: 100%;
  height: 30%;

  margin-top: 5px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &.ativo {
    margin-top: 1em !important;
    height: 96%;
  }

  .upload-box {
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 0.3em !important;
    transition: all 0.2s;
    ${(props) => props.isDragActive && dragActive}
    ${(props) => props.isDragReject && dragReject}
  }

  .plus {
    margin-right: 1em;
    margin-left: 3em;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid rgb(0, 150, 250);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 150, 250);
    border-radius: 100%;
    opacity: 0.5;

    &.red {
      color: red;
      border-color: red;
    }

    &.green {
      color: green;
      border-color: green;
    }
  }

  .info {
    font-size: 0.8em;
    line-height: 15px;
    opacity: 0.8;
    font-style: italic;
    width: 90%;
    margin-top: 5px;
  }

  .upload-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    font-size: 1.3em;
    font-weight: 500;
    height: max-content;
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
`;
export const UploadList = styled.div`
  width: 100%;
  height: 23em;
  display: flex;
  flex-direction: column;
  margin: 10px 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.03);
  overflow: overlay;
  opacity: 1;

  &.ativo {
    opacity: 0;
    height: 0;
  }
`;

export const UploadDisplay = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: overlay;
`;

export const UploadItem = styled.div`
  width: 100%;
  height: 3em;
  margin-top: 1px !important;
  opacity: 0.5;
  display: flex;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255);
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .thumb {
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &.btn {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &:hover {
      background-color: rgba(255, 255, 255);
    }

    svg {
      opacity: 0.6;
    }

    &.trash {
      color: red;
    }
  }

  .text {
    width: calc(100% - 6em);
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;

    p {
      margin-left: 20px !important;
      height: 60%;
      display: flex;
      align-items: center;

      &.info {
        height: 40%;
        transform: translateY(-0.5em);
        font-size: 0.8em;
      }
    }
  }
`;

export const UploadSend = styled.div`
  width: 100%;
  height: 4.5em;

  transform: translateY(-0.3em);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadBtn = styled.div`
  font-size: 0.8em;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    border: 1px solid rgba(0, 200, 250, 0.3);
    p {
      opacity: 0.7;
    }
  }
  p {
    color: black;
    padding: 5px 20px;
  }
`;

export const Uploaded = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: overlay;
  justify-content: center;

  @media screen and (max-width: 1600px) {
    justify-content: center;
  }
  h1 {
    font-size: 1.5em;
    opacity: 0.1;
    font-weight: 500;
    transform: translateY(-4em);
  }
  .contentx {
    width: max-content;
    max-width: 58.5em;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .limit {
    width: 100%;
    height: 10em;
    display: flex;
  }
`;

export const UploadedList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .table-header {
    width: 100%;
    height: 2em;
    background-color: white;
    display: flex;
  }

  .item {
    width: calc(100% - 30% - 5em);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;

    &.header {
      border-right: 1px solid rgba(0, 200, 250, 0.3);
      border-bottom: 1px solid rgba(0, 200, 250, 0.3);

      &.ico {
        width: 2em;

        input {
          width: 1em;
          height: 1em;
          padding: 0;
          border-radius: unset;
          background-color: white;
          border: 1px solid rgba(0, 200, 250, 1);
          cursor: pointer;
        }
      }
      p {
        width: 90%;
        height: max-content;
        padding: 0px 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8em;
        opacity: 0.7;
        padding-left: 1em;
      }
    }

    p {
      padding-left: 1em;
    }
    &.ico {
      width: 2em;
      justify-content: center;
    }

    &.small {
      width: 10%;
    }
  }

  .display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    overflow: overlay;

    .list-items {
      width: 100%;
      height: max-content;
      background-color: white;
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      opacity: 0.6;
      cursor: pointer;
      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 200, 250, 0.3);
      }
      p {
        padding: 9px 0;
        padding-left: 1em;
        font-size: 0.9em;
      }

      svg {
        opacity: 0.5;
      }

      .small {
        p {
          opacity: 0.7;
          font-size: 0.8em;
        }
      }
    }
  }
`;

export const UploadedItem = styled.div`
  width: 20em;
  height: 12em;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255);
  transition: all 0.1s;
  margin: 0.5em !important;
  margin-left: 1em !important;
  margin-top: 1em !important;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .content {
    width: 85%;
    height: 84%;
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    height: 2.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 8px;
    margin-bottom: 5px !important;

    .name {
      transform: translateY(0.2em);
      display: flex;
      flex-direction: column;
      margin-left: 0.8em;

      .texto {
        font-size: 0.9em;
        font-weight: 500;
      }

      .cnpj {
        font-size: 0.8em;
        font-weight: 500;
        opacity: 0.5;
        transform: translateY(-0.5em);
      }
    }

    .thumb {
      height: 2em;
      width: 2em;
      border-radius: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        color: white;
        font-size: 1em;
        font-weight: 500;
      }
    }
  }

  .infos {
    width: 100%;
    display: flex;

    .info {
      font-size: 0.8em;
      opacity: 0.6;
      transform: translateY(0);
    }
  }

  .box {
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 20px !important;

    &.bigger {
      width: 70%;
    }

    p {
      font-size: 0.9em;
      transform: translateY(-0.4em) translateX(0.1em);
    }
  }

  .nfe-tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress-bar {
    width: 100%;
    height: 0.3em;
    border-radius: 5px;
    margin: 5px 0;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .content {
      width: 30%;
      height: 100%;
      background-color: rgba(150, 200, 250, 1);
    }
  }
`;

export const UploadedMenu = styled.div`
  width: 100%;
  height: 1.7em;

  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-between;

  .btns {
    display: flex;
    width: max-content;

    .btn {
      width: max-content;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.03);
      background-color: rgba(255, 255, 255, 0.8);
      padding: 0 20px !important;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 0.9;
      }

      &.ativ {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 200, 250, 0.3);
      }

      p {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
        opacity: 0.7;

        svg {
          margin-right: 0.5em !important;
        }
      }
    }
  }

  .tools {
    display: flex;
    height: 100%;
    width: max-content;

    .btn {
      width: 2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      opacity: 0.5;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 1;
        border-bottom: 1px solid rgba(0, 200, 250, 0.3);
      }

      svg {
        font-size: 1em;
        opacity: 0.5;

        &.fa {
          font-size: 0.6em;
        }
      }
    }
  }
`;

export const UploadingBox = styled.div`
  width: 100%;
  height: max-content;
  min-height: 5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  &.google_xml {
    min-height: 10em;
  }

  &.done {
    opacity: 0.8;
  }

  &.todo {
    opacity: 0.3;
  }

  .content {
    width: 98%;
    height: 5em;
    min-height: 5em;
    display: flex;
    overflow: hidden;

    .thumb {
      width: 3em;
      height: 4em;
      transform: translateY(0.1em);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 1.5em;
        opacity: 0.5;
      }
    }

    .load {
      height: 100%;
      width: calc(100% - 4em);
      display: flex;
      flex-direction: column;

      .load-tag {
        width: 100%;
        height: 2.5em;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &.done {
          transform: translateY(0.3em);
        }

        p {
          font-size: 0.85em;
          opacity: 0.7;

          &.number {
            opacity: 0.6;
            font-size: 0.8em;
          }
        }
      }

      .load-box {
        width: 100%;
        height: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;

        .load-bar {
          width: 100%;
          height: 0.2em;
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          overflow: hidden;

          &.done {
            display: none;
          }

          .progress {
            width: 0%;
            height: 100%;
            background-color: rgba(0, 200, 250, 0.4);
            transition: all 0.2s;
          }
        }
      }
    }
  }

  .logs {
    width: 100%;
    height: max-content;
    min-height: 5em;
    max-height: 20em;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: overlay;
    background-color: rgba(0, 0, 0, 0.04);

    .log {
      width: 100%;
      height: 3em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.7);
      margin-top: 1px !important;

      &.todo {
        opacity: 0.3;
      }

      .thumb {
        width: 3em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.03);

        svg {
          opacity: 0.2;
        }
      }

      .infos {
        width: calc(100% - 3em);
        height: 3em;
        display: flex;
        flex-direction: column;

        .text {
          width: 100%;
          height: 2.7em;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin: 0 1em !important;
            font-size: 0.8em;
            opacity: 0.6;
          }
        }

        .load {
          width: 100%;
          height: 0.3em;
          background-color: rgba(0, 0, 0, 0.05);

          .progress {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 200, 100, 0.8);
            transition: all 0.2s;
          }
        }
      }
    }
  }
`;

export const Filter = styled.div`
  margin-top: 1.7em;
  background-color: rgba(245, 245, 245, 1);
  width: 25em;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: translateX(25em);
  transition: all 0.2s;
  position: fixed;
  z-index: 3;
  right: 0;

  .header {
    width: 100%;
    height: 1.5em;
    display: flex;

    .btn {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 0.6em;
      }
    }
  }
  &.ativ {
    transform: translateX(0em);
  }

  .dater {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

    .dates {
      width: 100%;
      height: 5em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;

      .date {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        transition: opacity 0.2s;
        outline: none;

        &.ativ {
          opacity: 1;
          border-top: 1px solid rgba(0, 200, 250, 0.3);
          border-right: 1px solid rgba(0, 200, 250, 0.3);
          border-left: 1px solid rgba(0, 200, 250, 0.3);
          background-color: white;
          transform: translateY(1px);
        }

        &:hover {
          opacity: 1;
        }

        &.bar {
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        .content {
          width: 8em;
          height: 3.5em;
          display: flex;
          align-items: center;

          img {
            width: 1em;
            opacity: 0.5;
            margin-right: 1em !important;
          }

          .infos {
            width: max-content;
            height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info {
              font-size: 0.7em;
              height: 1.4em;
            }

            .data {
              font-size: 0.8em;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .date-picker {
    width: 100%;
    height: max-content;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    border: 1px solid rgba(0, 200, 250, 0.3);

    .date-line {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1px;

      background-color: rgba(0, 0, 0, 0.1);
      .date-item {
        width: calc(100% / 6 - 1px);
        height: 2em;
        display: flex;
        background-color: white;
        border-left: 1px solid rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        cursor: pointer;
        &:hover,
        &.ativ {
          opacity: 1;
        }

        p {
          font-size: 0.8em;
          opacity: 0.8;
        }
      }
    }
  }

  .search-bar {
    width: 100%;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 1px solid rgba(0, 0, 0, 0.03);

    input {
      width: calc(100% - 2em);
      border-radius: 0px;
      height: 100%;
      padding: 0em;
      background-color: rgba(255, 255, 255, 1);
      color: black;
    }

    .btn {
      width: 2.2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);

      svg {
        font-size: 0.9em;
        opacity: 0.4;
      }
    }
  }

  .display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5em !important;

    .sped-item {
      width: 98%;
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255);
      transition: all 0.1s;

      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      .content {
        width: 85%;
        height: 84%;
        display: flex;
        flex-direction: column;
      }

      .header {
        width: 100%;
        height: 2.5em;
        padding-top: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;

        .name {
          transform: translateY(0.2em);
          display: flex;
          flex-direction: column;
          margin-left: 0.8em;

          .texto {
            font-size: 0.9em;
            font-weight: 500;
          }

          .cnpj {
            font-size: 0.8em;
            font-weight: 500;
            opacity: 0.5;
            transform: translateY(-0.5em);
          }
        }

        .thumb {
          height: 2em;
          width: 2em;
          border-radius: 100%;

          display: flex;
          justify-content: center;
          align-items: center;

          h2 {
            color: white;
            font-size: 1em;
            font-weight: 500;
          }
        }
      }
    }
  }

  .infos-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.1em !important;

    .box {
      width: calc(100% - 1em);
      height: max-content;

      display: flex;
      flex-direction: column;
      width: calc(100% -1em);
      margin-left: 1em !important;

      .tag {
        width: 100%;
        height: 1.5em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
        padding-left: 1em !important;
        transform: translateX(-1em);

        p {
          font-size: 0.7em;
          opacity: 0.5;
        }
      }

      .text {
        width: 100%;
        height: max-content;
        padding: 10px 0 !important;
        display: flex;
        align-items: center;

        &.desc {
          padding: 15px 0 !important;
        }

        p {
          font-size: 0.8em;
          width: 95%;
          line-height: 17px;
          opacity: 0.8;
        }
      }

      .infos {
        width: 100%;
        height: max-content;
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        padding-left: 1em !important;
        transform: translateX(-1em);

        .info {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.5em;
            opacity: 0.5;
          }

          .name {
            font-size: 0.7em;
            opacity: 0.5;
            margin-right: 0.7em !important;
          }

          .number {
            font-size: 0.8em;
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const Switch = styled.div`
  width: max-content;
  font-size: 1.1em;
  display: flex;
  height: 1.5em;

  position: fixed;

  z-index: 15;

  &.ativ {
    user-select: "none";
    opacity: 0.5;
  }

  .box {
    width: 4em;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s;

    &.init {
      svg {
        margin-left: 18px !important;
      }

      padding-right: 18px !important;
    }

    svg {
      margin-left: 1em !important;
      margin-right: 0.3em !important;
      font-size: 0.8em;
      opacity: 0.5;
    }

    &.end {
      transform: translateX(-18px);
      padding-right: 20px;
    }

    &:hover {
      opacity: 0.8;
      border: 1px solid rgba(0, 200, 250, 0.3);
    }

    &.ativ {
      opacity: 1;
      border: 1px solid rgba(0, 200, 250, 0.2);
    }

    p {
      font-size: 0.8em;
      user-select: none;
    }
  }

  .switch-box {
    height: 100%;
    width: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 15px;
    z-index: 15;
    transform: translateX(-10px);

    .switch {
      width: 2.5em;
      display: flex;
      height: 1em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      background-color: rgba(235, 235, 235);

      .circle {
        width: 1em;
        height: 1em;
        border-radius: 100%;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: all 1s;
        &.ativ {
          transform: translateX(1.5em);
        }
      }
    }
  }
`;

import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Container, Block, Header, Form } from './styles'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'

import ontax from '../ontax.png'

import { BsLock, BsPerson, BsEye, BsEyeSlash, BsCheckLg } from 'react-icons/bs'
import { AiOutlineIdcard } from 'react-icons/ai'
import { IoIosPerson } from 'react-icons/io'
import { VscLock } from 'react-icons/vsc'
import { useAuth } from '../../hooks/auth'
import google from './imgs/google.png'
import alerta from '../../utils/alerta'

import Particle from '../particles'

export default function Log() {
  const uri = process.env.REACT_APP_API_URI
  const { setLogin, login, tryLogin, tryLoginGoogle } = useAuth()
  const [status, setStatus] = useState('login')
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')

  function changeType(input) {
    var btn = document.getElementById(`${input}-pass-eye`)
    var antiBtn = document.getElementById(`${input}-pass-anti-eye`)
    var input = document.getElementById(input)

    if (input.type === 'password') {
      input.type = 'text'
      btn.style.display = 'none'
      antiBtn.style.display = 'flex'
    } else {
      input.type = 'password'
      btn.style.display = 'flex'
      antiBtn.style.display = 'none'
    }
  }
  const stages = [
    { tipo: 'login', component: <LoginBlock /> },
    { tipo: 'forgot', component: <ForgotBlock /> },
    { tipo: 'forgot_code', component: <ForgotCodeBlock /> },
    { tipo: 'new_password', component: <NewPassword /> },
  ]

  function LoginBlock() {
    async function tryLog() {
      var email = document.getElementById('loginEmail').value
      var password = document.getElementById('loginPass').value

      const user = {
        email,
        senha: password,
      }

      await tryLogin(user)
    }

    async function onSuccess(res) {
      await tryLoginGoogle(res.profileObj, gapi.auth.getToken().access_token)
    }

    function onFailure(res) {
      alerta('Usuário sem acesso', false, 'err', () => {})
    }

    return (
      <Block>
        <div className="content">
          <div className="header">
            <img src={ontax}></img>
          </div>
          <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID}
            onSuccess={onSuccess}
            onFailure={() => onFailure}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => (
              <div className="btn-google" onClick={renderProps.onClick}>
                <div className="thumb">
                  <img src={google} />
                </div>
                <div className="text">
                  <p>Login com Google</p>
                </div>
              </div>
            )}
          />
          <div className="inputs">
            <div className="input-box">
              <div className="thumb">
                <AiOutlineIdcard />
              </div>
              <input id="loginEmail" placeholder="Email" />
            </div>
            <div className="input-box">
              <div className="thumb">
                <VscLock />
              </div>
              <input id="loginPass" type="password" placeholder="Senha" />
              <div
                className="thumb end"
                onClick={() => changeType('loginPass')}
              >
                <BsEye id="loginPass-pass-eye" />
                <BsEyeSlash
                  style={{ display: 'none' }}
                  id="loginPass-pass-anti-eye"
                />
              </div>
            </div>
          </div>
          <div className="login-box">
            <p className="forgot-btn" onClick={() => setStatus('forgot')}>
              Esqueci a senha
            </p>
            <div className="btn" id="login-btn" onClick={tryLog}>
              <h2>Login</h2>
            </div>
          </div>
        </div>
      </Block>
    )
  }

  function ForgotBlock() {
    const notify = () => toast.success('Enviando código...')
    function sendToken() {
      const email_Info = document.getElementById('forgot_email').value
      if (email_Info !== '') {
        axios
          .post(`${uri}/forgot`, { email: email_Info })
          .then((res) => {
            if (res.status === 200) {
              notify()
              setTimeout(() => {
                setStatus('forgot_code')
                setEmail(email_Info)
              }, 1500);
              
            }
          })
          .catch((e) => {
            alerta('Email não encontrado!', false, 'err')
            
          })
      }
    }

    return (
      <Block>
        <div className="content">
          <div className="header">
            <img src={ontax}></img>
          </div>
          <h1>Esqueceu a senha?</h1>
          <h3>
            Escreva seu email abaixo e enviaremos um código para resetar sua
            senha.
          </h3>
          <div className="inputs">
            <div className="input-box">
              <div className="thumb">
                <AiOutlineIdcard />
              </div>
              <input
                type="email"
                placeholder="Email"
                className="input-emailforgot"
                required
                id="forgot_email"
              />
            </div>
            <div className="forgot-buttons">
              <button type="submit" placeholder="Continuar" onClick={sendToken}>
                <h2>Continuar</h2>
              </button>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <h4 onClick={() => setStatus('login')}>Voltar ao Login</h4>
            </div>
            <div className="progress-bar">
              <div className="return-page black"></div>
              <div className="actual-page gray"></div>
              <div className="next-page gray"></div>
            </div>
          </div>
        </div>
      </Block>
    )
  }

  function ForgotCodeBlock() {
    function verifyCode() {
      const notifyCode = () => toast.success('Token Válido!')
      const number1 = document.getElementById('forgot_1').value
      const number2 = document.getElementById('forgot_2').value
      const number3 = document.getElementById('forgot_3').value
      const number4 = document.getElementById('forgot_4').value
      const token_info = `${number1}${number2}${number3}${number4}`
      axios
        .post(`${uri}/token`, { email, token: token_info })
        .then((res) => {
          if (res.status === 200) {
            notifyCode()
            setTimeout(() => {
              setStatus('new_password')
              setToken(token_info)
            }, 1500);
           
          }
        })
        .catch((e) => {
          alerta('Token inválido', false, 'err')
        })
    }

    return (
      <Block>
        <div className="content">
          <div className="header">
            <img src={ontax}></img>
          </div>
          <h1>Código de Verificação</h1>
          <h3>
            Insira abaixo o código de verificação que foi enviado para o seu
            email.
          </h3>
          <div className="all-inputs">
            <input
              type="number"
              className="input-code"
              id="forgot_1"
              required
            />
            <input
              type="number"
              className="input-code"
              id="forgot_2"
              required
            />
            <input
              type="number"
              className="input-code"
              id="forgot_3"
              required
            />
            <input
              type="number"
              className="input-code"
              id="forgot_4"
              required
            />
          </div>
          <h5>
            Não recebeu o código?{' '}
            <span onClick={() => setStatus('forgot')}>Reenviar</span>
          </h5>
          <div className="forgot-buttons">
            <button type="submit" placeholder="Continuar" onClick={verifyCode}>
              <h2>Continuar</h2>
            </button>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
          </div>
          <div className="progress-bar">
            <div className="return-page gray"></div>
            <div className="actual-page black"></div>
            <div className="next-page gray"></div>
          </div>
        </div>
      </Block>
    )
  }

  function NewPassword() {
    const notifyPassword = () => toast.success('Senha atualizada!')
    function generateNewPassword() {
      const password = document.getElementById('forgot1').value
      const password_2 = document.getElementById('forgot2').value
      const check = true
      if (password !== password_2) {
        alerta('As senhas não conferem!', false, 'err')
        check = false
      } else {
        axios
          .post(`${uri}/update_pass`, { email, token, password })
          .then((res) => {
              notifyPassword()
              setTimeout(() => {
                setStatus('login')
              }, 1500);
            
          })
          .catch((e) => {
            alerta('Algo deu errado ', false, 'err')
          })
      }
    }

    return (
      <Block>
        <div className="content">
          <div className="header">
            <img src={ontax}></img>
          </div>
          <h1>Nova Senha</h1>
          <div className="newpassword-input">
            <div className="inputs forgot">
              <div className="input-box">
                <div className="thumb">
                  <VscLock />
                </div>
                <input
                  type="password"
                  id="forgot1"
                  placeholder="Nova senha"
                  required
                />
                <div
                  className="thumb end"
                  onClick={() => changeType('forgot1')}
                >
                  <BsEye id="forgot1-pass-eye" />
                  <BsEyeSlash
                    style={{ display: 'none' }}
                    id="forgot1-pass-anti-eye"
                  />
                </div>
              </div>
              <div className="input-box">
                <div className="thumb">
                  <VscLock />
                </div>
                <input
                  type="password"
                  id="forgot2"
                  placeholder="Confirmar senha"
                  required
                />
                <div
                  className="thumb end"
                  onClick={() => changeType('forgot2')}
                >
                  <BsEye id="forgot2-pass-eye" />
                  <BsEyeSlash
                    style={{ display: 'none' }}
                    id="forgot2-pass-anti-eye"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="forgot-buttons">
            <button
              type="submit"
              placeholder="Continuar"
              onClick={generateNewPassword}
            >
              <h2>Continuar</h2>
            </button>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
          </div>
          <div className="progress-bar">
            <div className="return-page gray"></div>
            <div className="actual-page gray"></div>
            <div className="next-page black"></div>
          </div>
        </div>
      </Block>
    )
  }

  useEffect(() => {
    ;(async () => {
      setTimeout(() => {
        try {
          document.getElementById('login').style.display = 'flex'
        } catch (e) {}
      }, 1000)
    })()
  }, [])

  return (
    <Container id="login">
      <Particle />
      {stages.find((obj) => obj.tipo === status).component}
    </Container>
  )
}

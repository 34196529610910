import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const AuthContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [login, setLogin] = useState(false);
  const [load, setLoad] = useState();
  const [infos, setInfos] = useState();

  const token = localStorage.getItem("app-token");

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  async function setStorage(res) {
    if (res.data.user_db.classe === process.env.REACT_APP_ADMIN) {
      setLogin(true);
      setUser(res.data.id);
      localStorage.setItem("app-token", res.data.token);
      localStorage.setItem("user_id", res.data.user_db._id);
      localStorage.setItem("projeto", res.data.user_db.projeto);
      localStorage.setItem("classe", res.data.user_db.classe);
      setUser(res.data.user_db._id);
      window.location.reload();
    } else {
      if (res.data.user_db.subclass === "free") {
        window.location.href = `${process.env.REACT_APP_FREE}/${res.data.token}/${res.data.user_db._id}/${res.data.user_db.projeto}`;
      }

      if (res.data.user_db.subclass === "pro") {
        window.location.href = `${process.env.REACT_APP_PRO}/${res.data.token}/${res.data.user_db._id}/${res.data.user_db.projeto}`;
      }
    }
  }

  async function tryLogin(user) {
    console.log("tryLogin called with user:", user);

    try {
      await axios.post(`${uri}/auth`, user).then((res) => {
        console.log("Login response:", res);

        if (res.status === 200) {
          setStorage(res);
        }
      });
    } catch (e) {
      console.log("Login error:", e);

      alerta("Usuário ou senha incorreta", false, "err", () => {});
    }
  }

  async function tryLoginGoogle(google, accessToken) {
    try {
      await axios.post(`${uri}/google`, { google, accessToken }).then((res) => {
        if (res.status === 200) {
          setStorage(res);
        }
      });
    } catch (e) {
      alerta("Usuário não autorizado", false, "err", () => {});
    }
  }

  function Logout() {
    setLogin();
    setUser();
    localStorage.clear();
  }

  useEffect(() => {
    axios
      .post(`${uri}/auth/check`, { user: localStorage.getItem("user_id") })
      .then((res) => {
        if (
          res.status === 200 &&
          localStorage.getItem("app-token") !== null &&
          localStorage.getItem("user_id") !== null
        ) {
          if (res.data.classe !== process.env.REACT_APP_ADMIN) {  
            if (res.data.subclass === "free") {
              Logout();
            } else {
              setUser(localStorage.getItem("user_id"));
              setLogin(true);
              setInfos(res.data);
            }
          } else {
            setUser(localStorage.getItem("user_id"));
            setLogin(true);
            setInfos(res.data);
          }
        } else {
          setLogin(false);
        }
      })
      .catch((err) => {
        setLogin(false);
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        setLogin,
        load,
        setLoad,
        tryLogin,
        tryLoginGoogle,
        Logout,
        infos,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  const {
    user,
    setUser,
    login,
    setLogin,
    load,
    setLoad,
    tryLogin,
    tryLoginGoogle,
    Logout,
    infos,
  } = context;
  return {
    user,
    setUser,
    login,
    setLogin,
    load,
    setLoad,
    tryLogin,
    tryLoginGoogle,
    Logout,
    infos,
  };
}

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import alerta from "../utils/alerta";

const ICMSContext = createContext();

const uri = process.env.REACT_APP_API_URI;

export default function ICMSProvider({ children }) {
  const [item, setItem] = useState();


  function openItem(item) {

    setItem(item);
    document.getElementById("icms-tool").style.display = "flex";
  }


  return (
    <ICMSContext.Provider
      value={{
        item,
        setItem,
        openItem,
      }}
    >
      {children}
    </ICMSContext.Provider>
  );
}

export function useICMS() {
  const context = useContext(ICMSContext);
  const { item, setItem, openItem } = context;
  return {
    item,
    setItem,
    openItem,
  };
}

export default function getColors() {
  var colors = {
    head: "rgb(10, 10, 23)",
    bg: "rgba(10, 10, 14, .05)",
    btn: "rgba(0, 0, 0, 0.5)",
    menu: "rgb(15, 15, 15, 0.9)",
    card: "rgb(45, 43, 49)",
    cardSecond: "rgb(25, 23, 29)",
  };

}

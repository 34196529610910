import { useState, useEffect } from "react";

import axios from "axios";

import { MdClose } from "react-icons/md";
import { BsFileText, BsDownload } from "react-icons/bs";
import { IoCubeOutline } from "react-icons/io5";

import { Container, Block, Header, Display } from "./styles";

import { useProjetos } from "../../../../../hooks/projetos";

import getDates from "../../../../../utils/getDates";
import reduzirStr from "../../../../../utils/reduzirStr";

const uri = process.env.REACT_APP_API_URI;

export default function Speds() {
  const [infos, setInfos] = useState([]);
  const [ativ, setAtiv] = useState();
  const { projeto } = useProjetos();

  useEffect(() => {
    if (projeto !== undefined) {
      axios.get(`${uri}/info/${projeto}`).then((res) => {
        setInfos(res.data);
      });
    }
  }, [projeto]);

  function handleClose() {
    document.getElementById("speds-tool").style.display = "none";
  }

  function checkClass(str) {
    if (str === ativ) {
      return "ativ";
    }
  }

  function handleChoose(str) {
    if (str === ativ) {
      setAtiv();
    } else {
      setAtiv(str);
    }
  }

  function getMonth(mes) {
    if (mes < 0) {
      mes = 1;
    }

    try {
      return getDates().months.find(
        (data) => mes === data.month
      ).str;
    } catch (e) {
      return mes;
    }
  }

  return (
    <Container id="speds-tool">
      <Block>
        <Header>
          <div className="close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Speds</p>
          </div>
        </Header>
        <Display>
          <div className="head">
            <div className="thumb"></div>
            <div className="data empresa">
              <p>EMPRESA</p>
            </div>
            <div className="data">
              <p>INÍCIO</p>
            </div>

            <div className="data">
              <p>FIM</p>
            </div>

            <div className="data">
              <p>SPED's</p>
            </div>

            <div className="data">
              <p>ENVIADOS</p>
            </div>

            <div className="data">
              <p>FALTANTES</p>
            </div>
          </div>
          <div className="display">
            {infos.length > 0 &&
              infos.map((obj) => (
                <div className="item-cont">
                  <div
                    className="item"
                    onClick={() => handleChoose(obj.empresa.cnpj)}
                  >
                    <div className="thumb">
                      <IoCubeOutline style={{ color: obj.empresa.color }} />
                    </div>
                    <div className="info">
                      <p>{obj.empresa.nome}</p>
                      <p className="cnpj">{obj.empresa.cnpj}</p>
                    </div>
                    <div className="data">
                      <p>
                        {getMonth(obj.periodo.start.mes)}{" "}
                        {obj.periodo.start.ano}
                      </p>
                    </div>
                    <div className="data">
                      <p>
                        {" "}
                        {getMonth(obj.periodo.end.mes)}{" "}
                        {obj.periodo.end.ano}
                      </p>
                    </div>
                    <div className="data">
                      <p>{obj.speds.length + obj.faltantes.length}</p>
                    </div>
                    <div className="data">
                      <p>{obj.speds.length}</p>
                    </div>
                    <div className="data">
                      <p>{obj.faltantes.length}</p>
                    </div>
                  </div>
                  <div className={`info-box ${checkClass(obj.empresa.cnpj)}`}>
                    <div className="info-header">
                      <div className="item-content thumb"></div>

                      <div className="item-content arquivo">
                        <p>ARQUIVO</p>
                      </div>

                      <div className="item-content">
                        <p>PERIODO</p>
                      </div>
                      <div className="item-content">
                        <p>NFe's</p>
                      </div>
                      <div className="item-content">
                        <p>ENCONTRADAS</p>
                      </div>
                      <div className="item-content">
                        <p>FALTANTES</p>
                      </div>
                    </div>
                    <div className="info-display">
                      {obj.faltantes.map((faltante) => (
                        <div className="info-item death">
                          <div className="thumb-info">
                            <IoCubeOutline />
                          </div>
                          <div className="item-content arquivo">
                            <h2>{obj.empresa.cnpj}</h2>
                          </div>
                          <div className="item-content">
                            <h2>
                              {String(faltante.mes).length === 1
                                ? `0${faltante.mes}`
                                : faltante.mes}{" "}
                              / {faltante.ano}
                            </h2>
                          </div>

                          <div className="item-content">
                            <h2>-</h2>
                          </div>

                          <div className="item-content">
                            <h2>-</h2>
                          </div>

                          <div className="item-content">
                            <h2>-</h2>
                          </div>
                        </div>
                      ))}

                      {obj.speds.map((obj) => (
                        <div className="info-item">
                          <div className="thumb-info">
                            <IoCubeOutline style={{ color: obj.color }} />
                          </div>
                          <div className="item-content arquivo">
                            <h2>{reduzirStr(obj.arquivo, 65)}</h2>
                          </div>
                          <div className="item-content">
                            <h2>
                              {String(obj.mes === 0 ? 12 : obj.mes).length === 1
                                ? `0${obj.mes}`
                                : obj.mes === 0
                                ? 12
                                : obj.mes}{" "}
                              / {obj.ano}
                            </h2>
                          </div>

                          <div className="item-content">
                            <h2>{obj.nfes}</h2>
                          </div>

                          <div className="item-content">
                            <h2>{obj.nfes - obj.faltantes}</h2>
                          </div>

                          <div className="item-content">
                            <h2>{obj.faltantes}</h2>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Display>
      </Block>
    </Container>
  );
}

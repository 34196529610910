import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 26;
  color: black;
  font-size: 1.1em;
`;

export const Block = styled.div`
  width: 60em;
  height: 40em;
  display: flex;
  background-color: rgba(240, 240, 240);
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250);

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
      background-color: white;
    }

    svg {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }

  .text {
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.8em;
      font-weight: 600;
      opacity: 0.6;
      transform: translateX(-1.8em);
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  height: calc(100% - 2em);
  overflow: overlay;
  flex-direction: column;

  .item-content {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;

    .item {
      width: 100%;
      height: 2.5em;
      background-color: white;
      display: flex;
      margin-top: 1px !important;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &.death {
        user-select: none;
        opacity: 0.3;

        &:hover {
          opacity: 0.3;
        }

        .thumb {
          &.download {
            cursor: default;
          }
        }
      }

      .thumb {
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        svg {
          font-size: 0.8em;
        }

        &.download {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          background-color: rgba(250, 250, 250);
          cursor: pointer;

          &:hover {
            background-color: white;
          }
        }
      }

      .text {
        width: calc(100% - 5em);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.5em;

        p {
          font-size: 0.8em;
          user-select: none;
        }
      }
    }

    .progress {
      width: 100%;
      height: 0.3em;
      background-color: rgba(0, 0, 0, 0.1);

      .load {
        width: 0%;
        height: 100%;
        background-color: rgba(0, 200, 0);
        transition: all 0.2s;
      }
    }
  }
`;

export const Infos = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .info {
    width: calc(100% / 6);
    height: 100%;
    border-right: 1px solid rgb(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .head-info {
      width: 100%;
      height: 1.5em;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);

      p {
        margin-left: 1em;
        font-size: 0.7em;
        opacity: 0.7;
      }
    }

    .head-text {
      width: 100%;
      height: calc(100% - 1.5em);
      display: flex;
      align-items: center;

      p {
        margin-left: 1em !important;
      }
    }
  }
`;

export const Table = styled.div`
  width: 100%;
  height: calc(100% - 10em);
  display: flex;
  flex-direction: column;

  .item {
    width: calc(100% / 8);
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    &.thumb {
      height: 100%;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.mini {
      width: 4em;
    }

    &.nfe {
      width: calc(100% - 31.5em);

      .text {
        width: calc(100% - 2em);
      }

      .thumb {
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        display: none;
        cursor: pointer;
        opacity: .5;
        
        &:hover {
          opacity: 1;
        }

        &.download {
          display: flex;
        }
      }
    }

    p {
      font-size: 0.8em;
      margin-left: 1em !important;
    }
  }

  .head {
    width: 100%;
    height: 1.5em;
    display: flex;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .display {
    width: 100%;
    height: calc(100% - 1.5em);
    display: flex;
    flex-direction: column;
    overflow: overlay;

    .items {
      width: 100%;
      min-height: 2em;
      display: flex;
      background-color: white;
      opacity: 0.6;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: none;
  color: black;
  scroll-snap-align: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.03);

  h1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10em;
  }
  justify-content: center;
  align-items: center;
`;
export const Dash = styled.div`
  height: 100%;
  width: calc(100% - 17em);
  opacity: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: overlay;

  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
  margin: 15% 0 !important;
  h1 {
    font-size: 2.5em;
    width: 85%;
  }

  .dash-display {
    width: 100%;
    height: calc(100% - 3em);
    display: flex;
    overflow-x: overlay;
    overflow-y: hidden;
  }

  &.ativ {
    width: 100%;

    #uploadx {
      width: 55em;
    }

    #fix {
      width: calc(100% - 55em);
    }
  }
`;

export const Profile = styled.div`
  width: 100%;
  height: 4em;
  display: flex;
  background-color: white;
  .thumb {
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.05);

    .circle {
      width: 2.5em;
      height: 2.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-position: center;
      background-size: cover;
    }
  }

  .text {
    width: calc(100% - 3em);
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 0.5em !important;
    p {
      font-size: 0.8em;
      opacity: 0.8;
      font-weight: 600;
      line-height: 16px;
      &.email {
        font-weight: 500;
        font-size: 0.7em;
        opacity: 0.5;
      }
    }
  }
`;
export const Apps = styled.div`
  width: 17em;

  height: 10%;
  display: flex;
  align-items: flex-start;
  .selection {
    flex-direction: column;
    display: flex;
    height: 10em;

    width: 100%;

    justify-content: center;
    align-items: center;

    .header {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      height: 2em;
      width: 100%;
      display: flex;
      background-color: white;
      .head {
        display: flex;
        align-items: center;
      }
      .thumb {
        display: flex;
        align-items: center;
        margin-left: 1em;
        svg {
          font-size: 0.8em;
          opacity: 0.8;
        }
      }
      p {
        margin-left: 10px;
        font-size: 0.9em;
        opacity: 1;
        line-height: 25px;
        font-family: "Montserrat", sans-serif;

        opacity: 0.7;
      }
    }
    .btns {
      width: 100%;
      height: 4.5em;
      display: flex;
      flex-direction: column;

      border-top: 1px solid rgba(0, 0, 0, 0.1);

      cursor: pointer;

      svg {
        font-size: 0.8em;
        margin-left: 1em;
        opacity: 1;
      }
      p {
        margin-left: 10px;
        font-size: 0.8em;
        opacity: 1;
        line-height: 25px;
        font-family: "Montserrat", sans-serif;
      }
      .area-btn {
        height: 1.5em;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        align-content: center;
        cursor: pointer;
        opacity: 0.7;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: white;

          opacity: 1;
        }
      }
    }
  }
`;

export const ProjetoInfo = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  .thumb {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    svg {
      opacity: 0.8;
      color: rgba(0, 100, 200);
    }
  }

  .text {
    width: calc(100% - 2.5em);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5em !important;

    p {
      font-size: 0.8em;
      opacity: 0.8;
      font-weight: 600;
      line-height: 16px;
      &.cnpj {
        font-weight: 500;
        font-size: 0.7em;
        opacity: 0.5;
        transform: translateX(0.1em);
      }
    }
  }
`;

export const TopBtn = styled.div`
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  transition: all 0.05s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;

  p {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Segoe UI" !important;
    transform: translateY(-0.05em);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
`;
export const Btn = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: all 0.5s;

  p {
    font-size: 0.9em;
    opacity: 0.7;
  }

  img {
    width: 1em;
    margin: 0 0.5em !important;
    margin-left: 1.5em !important;
  }

  svg {
    margin: 0 0.5em !important;
    margin-left: 1.5em !important;
    opacity: 0.3;
  }

  transition: all 0.2s;
  transition: border 0s;

  &.death {
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.8);

    &.ativ {
      opacity: 1;
      border-bottom: 1px solid rgba(0, 200, 250, 0.15);
      border-right: 1px solid white;
    }

    &:hover {
      opacity: 1;
    }

    p {
      font-size: 0.7em;
      font-weight: 500;
    }

    img {
      opacity: 0.5;
    }
  }

  &:hover {
    background-color: white;
  }

  &.dead {
    &:hover {
      background-color: unset;
    }
  }

  p.ico {
    font-size: 0.8em;
    opacity: 0.7;
  }
`;
export const TextBtn = styled.div`
  width: calc(100% - 3.5em);
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.9em;

  img {
    width: 6em;
    margin-left: 1em !important;
  }
`;
export const Thumb = styled.div`
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 0 1em !important;
  margin-right: 0.7em !important;

  p {
    font-size: 0.8em;
    color: white;
  }
`;
export const Close = styled.div`
  width: 2em;
  height: 1.5em;
  transition: all ease-in cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s;

  svg {
    font-size: 1.5em;
    opacity: 0.5;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  }
`;
export const MenuBox = styled.div`
  width: 17em;
  height: 90%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
`;
export const Menu = styled.div`
  width: 17em;
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: rgb(247, 246, 243);
  transition: all 0.2s;
  z-index: 18;

  &:hover ${Close} {
    opacity: 1;
  }

  &.ativ {
    width: 0;

    ${MenuBox} {
      background-color: rgb(255, 255, 255);

      margin-top: 3em !important;
      float: left;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      transform: translateX(0em);
    }

    ${Close} {
      display: none;
    }
  }
`;

export const TopBar = styled.div`
  width: 100%;
  height: 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  overflow: hidden;
  align-items: center;
  z-index: 2;

  .infos {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      width: max-content;
      height: 3em;
      display: flex;
      align-items: center;
      border-left: 1px rgba(0, 0, 0, 0.1);
      background-color: white;
      padding: 0 20px !important;
      svg {
        margin-right: 0.2em;
        opacity: 0.5;
      }

      p {
        opacity: 0.9;
      }
    }
  }

  .logout {
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.04);
    opacity: 0.3;
    cursor: pointer;

    svg {
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      svg {
        opacity: 0.5;
      }
    }
  }
`;

export const MenuBtn = styled.div`
  width: 2em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin: 0 0.5em !important;
  cursor: pointer;
  transition: all 0.2s;

  #topMenuOpen {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    ${MenuBox} {
      transform: translateX(0);
    }
    #topMenuIco {
      display: none;
    }

    #topMenuOpen {
      display: flex;
    }
  }

  &.menu-btn {
    width: 3em;
    height: 2.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 !important;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }

    svg {
      opacity: 0.5;
    }

    &.ativ {
      border-bottom: 3px solid rgba(0, 200, 250, 0.2);
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

export const ContainerBox = styled.div`
  width: 35em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.03);

  &.bigger {
    align-items: flex-start;
    width: calc(100% - 35em);
  }

  &.align {
    justify-content: center;
  }
`;

export const UploadBox = styled.div`
  width: 90%;
  height: 40em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
`;

const dragActive = css`
  border-color: #78e5d5;

  background-color: rgba(0, 250, 100, 0.03);
`;

const dragReject = css`
  border-color: #e57878;
  background-color: rgba(250, 0, 0, 0.03);
`;

export const Upload = styled.div`
  width: 100%;
  height: 30%;

  margin-top: 5px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &.ativo {
    height: 90%;
  }

  .upload-box {
    width: 95%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 0.9em;

    transition: all 0.2s;

    ${(props) => props.isDragActive && dragActive}
    ${(props) => props.isDragReject && dragReject}
  }

  .plus {
    margin-right: 1em;
    margin-left: 3em;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid rgb(0, 150, 250);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 150, 250);
    border-radius: 100%;
    opacity: 0.5;

    &.red {
      color: red;
      border-color: red;
    }

    &.green {
      color: green;
      border-color: green;
    }
  }

  .info {
    font-size: 0.8em;
    line-height: 15px;
    opacity: 0.8;
    font-style: italic;
    width: 90%;
    margin-top: 5px;
  }

  .upload-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h2 {
    font-size: 1.3em;
    font-weight: 500;
    height: max-content;
    display: flex;
    align-items: center;
    opacity: 0.7;
  }
`;
export const UploadList = styled.div`
  width: 100%;
  height: 23em;
  display: flex;
  flex-direction: column;
  margin: 10px 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.03);
  overflow: overlay;
  opacity: 1;

  &.ativo {
    opacity: 0;
    height: 0;
  }
`;

export const UploadDisplay = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: overlay;
`;

export const UploadItem = styled.div`
  width: 100%;
  height: 3em;
  margin-top: 1px !important;
  opacity: 0.5;
  display: flex;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255);
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .thumb {
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &.btn {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &:hover {
      background-color: rgba(255, 255, 255);
    }

    svg {
      opacity: 0.6;
    }

    &.trash {
      color: red;
    }
  }

  .text {
    width: calc(100% - 6em);
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;

    p {
      margin-left: 20px !important;
      height: 60%;
      display: flex;
      align-items: center;

      &.info {
        height: 40%;
        transform: translateY(-0.5em);
        font-size: 0.8em;
      }
    }
  }
`;

export const UploadSend = styled.div`
  width: 100%;
  height: 4.5em;

  transform: translateY(-0.3em);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadBtn = styled.div`
  font-size: 0.8em;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    border: 1px solid rgba(0, 200, 250, 0.3);
    p {
      opacity: 0.7;
    }
  }
  p {
    color: black;
    padding: 5px 20px;
  }
`;

export const Uploaded = styled.div`
  width: 59em;
  height: max-content;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: overlay;
  @media screen and (max-width: 1600px) {
    justify-content: center;
  }
`;

export const UploadedItem = styled.div`
  width: 20em;
  height: 11em;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255);
  transition: all 0.1s;
  margin: 0.5em !important;
  margin-left: 1em !important;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .content {
    width: 85%;
    height: 84%;
    display: flex;
    flex-direction: column;
  }

  .header {
    width: 100%;
    height: 2.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;

    .name {
      transform: translateY(0.2em);
      display: flex;
      flex-direction: column;
      margin-left: 0.8em;

      .texto {
        font-size: 0.9em;
        font-weight: 500;
      }

      .cnpj {
        font-size: 0.8em;
        font-weight: 500;
        opacity: 0.5;
        transform: translateY(-0.5em);
      }
    }

    .thumb {
      height: 2em;
      width: 2em;
      border-radius: 100%;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        color: white;
        font-size: 1em;
        font-weight: 500;
      }
    }
  }

  .infos {
    width: 100%;
    display: flex;

    .info {
      font-size: 0.7em;
      opacity: 0.6;
      transform: translateY(0);
    }
  }

  .box {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.bigger {
      width: 70%;
    }

    p {
      font-size: 0.9em;
      transform: translateY(-0.4em) translateX(0.1em);
    }
  }

  .nfe-tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress-bar {
    width: 100%;
    height: 0.3em;
    border-radius: 5px;
    margin: 5px 0;
    background-color: rgba(0, 0, 0, 0.1);

    .content {
      width: 30%;
      height: 100%;
      background-color: rgba(150, 200, 250, 1);
    }
  }
`;

export const ExploreContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ExploreFilter = styled.div`
  width: 25em;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.03);

  .dater {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

    .dates {
      width: 100%;
      height: 5em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.5);

      .date {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.bar {
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        .content {
          width: 8em;
          height: 3.5em;
          display: flex;
          align-items: center;

          img {
            width: 1em;
            opacity: 0.5;
            margin-right: 1em !important;
          }

          .infos {
            width: max-content;
            height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info {
              font-size: 0.7em;
              height: 1.4em;
            }

            .data {
              font-size: 0.8em;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .search-bar {
    width: 100%;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 1px solid rgba(0, 0, 0, 0.03);

    input {
      width: calc(100% - 2em);
      border-radius: 0px;
      height: 100%;
      padding: 0em;
      background-color: rgba(255, 255, 255, 1);
      color: black;
    }

    .btn {
      width: 2.2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);

      svg {
        font-size: 0.9em;
        opacity: 0.4;
      }
    }
  }

  .display {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5em !important;

    .sped-item {
      width: 98%;
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255);
      transition: all 0.1s;

      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      .content {
        width: 85%;
        height: 84%;
        display: flex;
        flex-direction: column;
      }

      .header {
        width: 100%;
        height: 2.5em;
        padding-top: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;

        .name {
          transform: translateY(0.2em);
          display: flex;
          flex-direction: column;
          margin-left: 0.8em;

          .texto {
            font-size: 0.9em;
            font-weight: 500;
          }

          .cnpj {
            font-size: 0.8em;
            font-weight: 500;
            opacity: 0.5;
            transform: translateY(-0.5em);
          }
        }

        .thumb {
          height: 2em;
          width: 2em;
          border-radius: 100%;
          background-color: black;
          display: flex;
          justify-content: center;
          align-items: center;

          h2 {
            color: white;
            font-size: 1em;
            font-weight: 500;
          }
        }
      }
    }
  }

  .infos-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.1em !important;

    .box {
      width: calc(100% - 1em);
      height: max-content;

      display: flex;
      flex-direction: column;
      width: calc(100% -1em);
      margin-left: 1em !important;

      .tag {
        width: 100%;
        height: 1.5em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
        padding-left: 1em !important;
        transform: translateX(-1em);

        p {
          font-size: 0.7em;
          opacity: 0.5;
        }
      }

      .text {
        width: 100%;
        height: max-content;
        padding: 10px 0 !important;
        display: flex;
        align-items: center;

        &.desc {
          padding: 15px 0 !important;
        }

        p {
          font-size: 0.8em;
          width: 95%;
          line-height: 17px;
          opacity: 0.8;
        }
      }

      .infos {
        width: 100%;
        height: max-content;
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        padding-left: 1em !important;
        transform: translateX(-1em);

        .info {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.5em;
            opacity: 0.5;
          }

          .name {
            font-size: 0.7em;
            opacity: 0.5;
            margin-right: 0.7em !important;
          }

          .number {
            font-size: 0.8em;
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const ExploreSpeds = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 100%;
    height: 2em;

    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    display: flex;

    .filter-btn {
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.03);
      opacity: 0.5;
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.5);

      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 0.8em;
        opacity: 0.5;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;

    .card {
      width: 18em;
      height: max-content;
      flex-direction: column;
      margin-top: 1em !important;
      margin-bottom: 0 !important;
      margin-left: 1em !important;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
      opacity: 0.7;
      cursor: pointer;
      transition: all 0.2s;
      background-color: rgb(255, 255, 255);

      &:hover {
        opacity: 1;
      }

      .header {
        width: 100%;
        height: max-content;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        display: flex;
        align-items: center;

        &.between {
          justify-content: space-between;

          .info {
            svg {
              margin-right: 1em !important;
              opacity: 0.6;
            }
          }
        }

        .reg {
          margin-left: 1em !important;
          margin-right: 0.5em !important;
          font-weight: 600;
        }

        .thumb {
          width: 2em;
          height: 2em;
          border-radius: 100%;
          margin-left: 0.7em !important;
          display: flex;
          background-color: rgb(0, 0, 0);
          align-items: center;
          justify-content: center;
          color: white;
        }

        .text {
          width: max-content;
          height: 100%;
          margin-left: 1em !important;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .name {
            width: 14em;
            height: max-content;
            font-size: 0.8em;
            line-height: 18px;
          }

          .info {
            font-size: 0.7em;
          }
        }
      }

      .box-info {
        width: 100%;
        height: max-content;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        p {
          width: 90%;
          font-size: 0.75em;
          line-height: 17px;
          opacity: 0.8;
        }
      }

      .box {
        width: 100%;
        height: 4em;
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-weight: 500;
          font-size: 1.3em;
        }

        .text {
          display: flex;
          width: 100%;
          height: max-content;
          margin-left: 1em !important;
        }
      }
      .infos {
        width: 100%;
        height: 2em;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: flex-start;

        .info {
          height: 100%;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid rgba(0, 0, 0, 0.1);

          svg {
            margin: 0 1em !important;
            font-size: 0.8em;
          }
          p {
            font-size: 0.8em;
            margin-right: 1.5em !important;
          }
        }
      }

      .desc {
        margin-left: 1em !important;
        font-size: 0.8em;
        opacity: 0.7;
      }
    }
  }
`;

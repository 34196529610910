import { useState, useEffect } from "react";

import axios from "axios";
import FileDownload from "js-file-download";

import { MdClose } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { VscLibrary } from "react-icons/vsc";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

import { Container, Block, Header, Display, Infos, Table } from "./styles";

import { useProjetos } from "../../../../../hooks/projetos";

import Loading from "../../../../../utils/loading";
import { useUpload } from "../../../../../hooks/upload";
import { useICMS } from "../../../../../hooks/icms";

const uri = process.env.REACT_APP_API_URI;

export default function ICMS() {
  const { projeto, status: upload_status } = useProjetos();
  const { item } = useICMS();
  const [infos, setInfos] = useState([]);

  function handleClose() {
    document.getElementById("icms-tool").style.display = "none";
  }

  useEffect(() => {
    if (item !== undefined) {
      setInfos([]);
      axios.get(`${uri}/icms/${projeto}/${item.cod}`).then((res) => {
        setInfos(res.data);
      });
    }
  }, [item]);

  function format(num) {
    if (!isNaN(parseFloat(num))) {
      return parseFloat(num).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  }

  function downloadFile(file) {
    axios
      .post(`${uri}/icms-download/${projeto}/${file}`, {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, `${file}.xml`);
      });
  }

  return (
    <Container id="icms-tool">
      <Block>
        {item !== undefined && (
          <>
            <Header>
              <div className="close" onClick={handleClose}>
                <MdClose />
              </div>
              <div className="text">
                <p>
                  {item.cod} - {item.desc}
                </p>
              </div>
            </Header>
            <Infos>
              <div className="info">
                <div className="head-info">
                  <p>QTD compra</p>
                </div>
                <div className="head-text">
                  <p>{item.entradas_qtd}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Valor compra</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.entradas_vlr)}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Valor unit compra</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.entradas_vlr_unit)}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>QTD venda</p>
                </div>
                <div className="head-text">
                  <p>{item.saidas_qtd}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Valor venda</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.saidas_vlr)}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Valor unit venda</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.saidas_vlr_unit)}</p>
                </div>
              </div>
            </Infos>
            <Infos>
              <div className="info">
                <div className="head-info">
                  <p>NCM</p>
                </div>
                <div className="head-text">
                  <p>{item.ncm}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Aliq ICMS</p>
                </div>
                <div className="head-text">
                  <p>18 %</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>MVA</p>
                </div>
                <div className="head-text">
                  <p>70 %</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>ICMS pago unit</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.icms_pago / item.entradas_qtd)}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>ICMS realizado unit</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.icms_realizado / item.saidas_qtd)}</p>
                </div>
              </div>
              <div className="info">
                <div className="head-info">
                  <p>Saldo</p>
                </div>
                <div className="head-text">
                  <p>R$ {format(item.saldo)}</p>
                </div>
              </div>
            </Infos>
          </>
        )}
        <Table>
          <div className="head">
            <div className="item">
              <p>Data</p>
            </div>
            <div className="item">
              <p>Tipo</p>
            </div>

            <div className="item">
              <p>CFOP</p>
            </div>
            <div className="item">
              <p>Vlr Unit</p>
            </div>
            <div className="item mini">
              <p>Qtd</p>
            </div>
            <div className="item">
              <p>Total</p>
            </div>
            <div className="item nfe">
              <p>NFE</p>
            </div>
          </div>
          <div className="display">
            {infos.length > 0 &&
              infos.map((obj) => (
                <div className="items">
                  <div className="item">
                    <p>{new Date(obj.data).toLocaleDateString()}</p>
                  </div>
                  <div className="item">
                    <p
                      style={{
                        color: `${obj.tipo === "entrada" ? "blue" : "green"}`,
                      }}
                    >
                      {obj.tipo}
                    </p>
                  </div>

                  <div className="item">
                    <p>{obj.cfop}</p>
                  </div>

                  <div className="item">
                    <p>R$ {format(obj.vlr_unit)}</p>
                  </div>

                  <div className="item mini">
                    <p>{obj.qtd}</p>
                  </div>

                  <div className="item">
                    <p>R$ {format(obj.total)}</p>
                  </div>

                  <div className="item nfe">
                    <div className="text">
                      <p>{obj.nfe}</p>
                    </div>
                    <div className={`thumb ${obj.check && "download"}`} onClick={() => downloadFile(obj.nfe)}>
                      <AiOutlineDownload />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Table>
      </Block>
    </Container>
  );
}

import React, { useState, useEffect } from "react";

import {
  Container,
  Dash,
  Profile,
  ProjetoInfo,
  Menu,
  MenuBox,
  TopBtn,
  Btn,
  TextBtn,
  Thumb,
  Close,
  TopBar,
  MenuBtn,
  Apps,
} from "./styles";

import UploadDash from "./components/upload";
import Explore from "./components/explore";
import Projetos from "./components/black_screen/projetos";
import Charts from "./components/charts";
import ICMSDash from "./components/icms";
import alerta from "../../utils/alerta";
import timer from "../../utils/timer";
import logo from "../ontax.png";
import {
  IoIosArrowRoundBack,
  IoIosArrowRoundForward,
  IoIosCube,
} from "react-icons/io";
import { FiMenu } from "react-icons/fi";
import { BsFillGearFill, BsListCheck } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";

import { BiCube, BiLibrary } from "react-icons/bi";

import { FaCloudUploadAlt, FaToolbox } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoStatsChartSharp, IoCubeOutline } from "react-icons/io5";
import { AiTwotoneTool } from "react-icons/ai";
import { GrPowerReset } from "react-icons/gr";
import { HiDownload } from "react-icons/hi";
import { FcGoogle } from "react-icons/fc";
import folderPng from "./imgs/folder.png";

import { useProjetos } from "../../hooks/projetos";
import { useAuth } from "../../hooks/auth";
import axios from "axios";
import { useUpload } from "../../hooks/upload";

const uri = process.env.REACT_APP_API_URI;

export default function Dashboard() {
  const { Logout, login, infos } = useAuth();
  const [menu, setMenu] = useState([]);
  const [dash, setDash] = useState("upload");
  const { projeto, status, setStatus, setProjeto, empresas, projeto_infos } =
    useProjetos();
  const [modulo, setModulo] = useState("files");
  const { status: upload_status, setAtualize } = useUpload();

  const dashboards = [
    { dash: "upload", component: <UploadDash />, btn: <FaCloudUploadAlt /> },
    ///{ dash: "speds", component: <Explore></Explore>, btn: <MdDashboard /> },
    //{ dash: "list", component: <></>, btn: <BsListNested /> },
    { dash: "charts", component: <Charts />, btn: <IoStatsChartSharp /> },
  ];

  const icms_dash = [
    { dash: "charts", component: <Charts />, btn: <IoStatsChartSharp /> },
  ];

  function checkClass(str) {
    if (dash === str) {
      return "ativ";
    }
    return "";
  }

  function toggleMenu() {
    if (menu === true) {
      document.getElementById("menu").classList.add("ativ");
      document.getElementById("dash").classList.add("ativ");
      setMenu(false);
      setTimeout(() => {
        document.getElementById("menu-box").style.transform =
          "translateX(-17em)";
      }, 100);
    } else {
      document.getElementById("menu").classList.remove("ativ");
      document.getElementById("dash").classList.remove("ativ");
      setMenu(true);
      document.getElementById("menu-box").style.transform = "translateX(0em)";
    }
  }

  function choseArea(str) {
    if (str === "free") {
      window.location.href = `${
        process.env.REACT_APP_FREE
      }/${localStorage.getItem("app-token")}/${localStorage.getItem(
        "user_id"
      )}/${projeto}`;
    }
    if (str === "pro") {
      window.location.href = `${
        process.env.REACT_APP_PRO
      }/${localStorage.getItem("app-token")}/${localStorage.getItem(
        "user_id"
      )}/${projeto}`;
    }
    if (str === "admin") {
      window.location.href = `${
        process.env.REACT_APP_ADMIN_URI
      }/${localStorage.getItem("app-token")}/${localStorage.getItem(
        "user_id"
      )}/${projeto}`;
    }
  }
  function TopMenu() {
    return (
      <TopBar>
        {!menu && (
          <MenuBtn onClick={toggleMenu}>
            <FiMenu id="topMenuIco" />
            <IoIosArrowRoundForward id="topMenuOpen" />
          </MenuBtn>
        )}
        {modulo === "files" &&
          dashboards.map((obj) => (
            <MenuBtn
              key={dashboards.indexOf(obj)}
              className={`${"menu-btn"} ${checkClass(obj.dash)}`}
              onClick={() => setDash(obj.dash)}
            >
              {obj.btn}
            </MenuBtn>
          ))}
        <div className="logout" onClick={Logout}>
          <MdLogout />
        </div>
      </TopBar>
    );
  }

  function openChecklist() {
    document.getElementById("tools").style.display = "flex";
  }

  function openGmail() {
    document.getElementById("gmail-tools").style.display = "flex";
    setAtualize(true);
  }

  function openProjetos() {
    document.getElementById("projs").style.display = "flex";
  }

  function openDownload() {
    setAtualize(true);
    document.getElementById("download-tool").style.display = "flex";
  }

  function resetProjeto() {
    var files = [];

    function sendTrigger() {
      axios.get(`${uri}/tools/reset/${projeto}`).then((res) => {
        if (res.status === 200) {
          files = res.data;

          const task = {
            projeto,
            task: "atualize_check",
            files,
          };

          axios.post(`${uri}/tasks`, task).then((resp) => {
            if (resp.status === 200) {
              window.location.reload();
            }
          });
        }
      });
    }

    if (upload_status !== "uploading") {
      alerta(
        "Tem certeza que deseja restaurar o projeto a partir das informações extraídas a partir dos arquivos incluídos no banco de dados?",
        true,
        "err",
        sendTrigger
      );
    } else {
      alerta(
        "Aguarde as tarefas atuais serem finalizadas para restaurar o projeto",
        false,
        "err"
      );
    }
  }

  function startIcms() {
    function start() {
      axios.post(`${uri}/tasks`, {
        projeto,
        task: "icms",
        files: [],
        goal: 100,
      });
    }

    alerta("Deseja processar o ICMS?", true, "err", start);
  }

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        document.getElementById("disp").style.display = "flex";
      }, 1000);
    })();
  }, []);

  return (
    <Container id="disp">
      <Projetos />
      <Menu id="menu">
        <MenuBox id="menu-box">
          <TopBtn>
            <TextBtn>
              <img src={logo} />
            </TextBtn>
            <Close onClick={toggleMenu}>
              <IoIosArrowRoundBack />
            </Close>
          </TopBtn>
          {projeto_infos !== undefined && projeto_infos !== null && (
            <ProjetoInfo>
              <div className="thumb">
                <IoCubeOutline />
              </div>
              <div className="text">
                <p>{projeto_infos.nome}</p>
                <p className="cnpj">{projeto_infos.cnpj}</p>
              </div>
            </ProjetoInfo>
          )}
          {infos !== undefined && infos.google !== undefined && (
            <Profile>
              <div className="thumb">
                <div
                  className="circle"
                  style={{ backgroundImage: `url(${infos.google.imageUrl})` }}
                ></div>
              </div>
              <div className="text">
                <p>{infos.google.name}</p>
                <p className="email">{infos.google.email}</p>
              </div>
            </Profile>
          )}
          <Btn id="settingsBtn" style={{ display: "none" }}>
            <BsFillGearFill />
            <p>Configurações</p>
          </Btn>
          {localStorage.getItem("classe") === process.env.REACT_APP_ADMIN && (
            <Btn onClick={openProjetos}>
              <BiLibrary />
              <p>Meus projetos</p>
            </Btn>
          )}

          {empresas.length > 0 && upload_status !== "uploading" && (
            <>
              {localStorage.getItem("classe") ===
                process.env.REACT_APP_ADMIN && (
                <Btn onClick={openGmail}>
                  <FcGoogle />
                  <p>Gmail API</p>
                </Btn>
              )}

              <Btn onClick={openChecklist}>
                <BsListCheck />
                <p>Checklist</p>
              </Btn>

              <Btn onClick={openDownload}>
                <HiDownload />
                <p>Downloads</p>
              </Btn>
            </>
          )}

          <Btn onClick={resetProjeto}>
            <GrPowerReset />
            <p>Restaurar projeto</p>
          </Btn>

          <Btn className="dead"></Btn>
          <Btn
            className={`death ${modulo === "files" && "ativ"}`}
            onClick={() => setModulo("files")}
          >
            <IoIosCube />
            <p>ARQUIVOS</p>
          </Btn>

          <Btn
            className={`death ${modulo === "icms" && "ativ"}`}
            onClick={() => setModulo("icms")}
          >
            <IoIosCube />
            <p>ICMS ST</p>
          </Btn>
          <Apps>
            <div className="selection">
              <div className="header">
                <div className="thumb">
                  <MdDashboard />
                </div>
                <div className="head">
                  <p>Areas de trabalho</p>
                </div>
              </div>
              <div className="btns">
                <div className="area-btn" onClick={() => choseArea("admin")}>
                  <IoCubeOutline />
                  <p>Admin</p>
                </div>
                <div className="area-btn" onClick={() => choseArea("pro")}>
                  <IoCubeOutline />
                  <p>Pro</p>
                </div>
                <div className="area-btn" onClick={() => choseArea("free")}>
                  <IoCubeOutline />
                  <p>Free</p>
                </div>
              </div>
            </div>
          </Apps>
        </MenuBox>
      </Menu>

      <Dash id="dash">
        <TopMenu />
        {modulo === "files" && (
          <div className="dash-display">
            {dashboards.find((obj) => obj.dash === dash).component}
          </div>
        )}
        {modulo === "icms" && (
          <div className="dash-display">
            <ICMSDash />
          </div>
        )}
      </Dash>
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: black;
  display: none;

  p {
    font-size: 0.8em;
    margin-left: 10px !important;
  }
`;

export const Header = styled.div`
  width: 65em;
  display: flex;
  height: 1.5em;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);

  .btn {
    width: 1.5em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;

    svg {
      font-size: 0.8em;
    }
  }
`;

export const Block = styled.div`
  width: 65em;
  height: 70%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;

  .infos {
    width: 30em;
    height: 100%;
    display: flex;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    flex-direction: column;

    .head {
      width: 100%;
      height: 1.5em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      align-items: center;
      margin-top: 1px !important;

      &.mini {
        height: 1.2em;
        opacity: 0.7;
        p {
          font-size: 0.7em;
        }
      }

      .head-item {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(0, 0, 0, 0.03);
      }
    }
  }

  .item {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.03);
    height: 4em;
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 1px !important;
    opacity: 0.7;
    cursor: pointer;

    &.light {
      opacity: 1;
      background-color: rgba(255, 255, 255, .8);
      height: 4.5em;


      .infos {
        .prod-infos {
          height: 2em;
          background-color: white;

          p {
            opacity: 1;
            font-size: .9em;
            font-weight: 500;
            white-space: nowrap;
            
          }

          svg {
            opacity: .5;
          }
        }
      }
    }

    &:hover {
      opacity: 1;
    }

    &.big {
      height: 5em;
      background-color: rgba(255, 255, 255, 0.8);
      opacity: 1;
      cursor: unset;
    }

    .thumb {
      width: 4em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.03);
    }

    .infos {
      width: calc(100%);
      display: flex;
      flex-direction: column;

      .load-infos {
        width: 100%;
        height: 3em;
        display: flex;
        flex-direction: column;

        .text {
          width: 100%;
          display: flex;
          transform: translateY(0.3em);
          justify-content: space-between;

          p {
            margin-right: 10px !important;
            white-space: nowrap;
          }
        }

        .load {
          width: 100%;
          height: 1em;
          display: flex;
          align-items: center;

          .load-bar {
            width: 100%;
            height: 0.3em;
            display: flex;
            background-color: rgba(0, 0, 0, 0.03);
            margin: 0 10px !important;

            .progress {
              width: 50%;
              height: 100%;
              background-color: red;
              opacity: 0.6;
            }
          }
        }
      }

      .prod-infos {
        width: 100%;
        height: 1.3em;
        display: flex;
        border-top: 1px solid rgba(0, 0, 0, 0.03);

        .info {
          width: max-content;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0 6px !important;
          opacity: 0.7;

          p {
            margin-left: 5px !important;
          }

          svg {
            font-size: 0.8em;
          }
        }
      }
    }
  }

  .table {
    width: calc(100% - 30em);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;

    .head {
      width: 100%;
      height: 1.3em;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    }
  }
`;

import { useState, useEffect } from "react";
import axios from "axios";

import { Container, Block, Content, Send, Dates, DatePicker } from "./styles";

import google from "./imgs/google.png";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import { MdClose, MdCheck } from "react-icons/md";
import { BsCalendar3Week } from "react-icons/bs";
import { useAuth } from "../../../../../hooks/auth";
import { useProjetos } from "../../../../../hooks/projetos";

import getDates from "../../../../../utils/getDates";

import alerta from "../../../../../utils/alerta";
import { useUpload } from "../../../../../hooks/upload";

const uri = process.env.REACT_APP_API_URI;

export default function Gmail() {
  const { projeto } = useProjetos();
  const { user, infos } = useAuth();
  const [logged, setLog] = useState(false);
  const [dates, setDates] = useState({
    start: { mes: 0, ano: 2011 },
    end: { mes: 11, ano: 2022 },
  });
  const [modo, setModo] = useState("inicio");

  const { atualize, setAtualize } = useUpload();

  const anos = getDates().years;
  const meses = getDates().months;

  useEffect(() => {
    if (infos !== undefined && atualize === true) {

      var config = {
        method: "get",
        url:
          "https://www.googleapis.com/gmail/v1/users/me/messages?q=" +
          "has:attachment",
        headers: {
          Authorization: `Bearer ${infos.accessToken} `,
        },
      };

      axios
        .get(config)
        .then((res) => {
          if (res.status === 200) {
            setLog(true);

          }
        })
        .catch((res) => {
          setLog(false);
        });


      setAtualize(false);
    } else {
      setLog(false);
      setAtualize(false);
    }
  }, [infos, atualize]);
  

  function handleClose() {
    document.getElementById("gmail-tools").style.display = "none";
  }
  function onSuccess(res) {
    console.log(res.profileObj);

    axios
      .post(`${uri}/auth/google`, {
        user_id: user,
        google: res.profileObj,
        accessToken: gapi.auth.getToken().access_token,
      })
      .then((res) => {
        setLog(true);
      });
  }

  function onFailure(res) {
    console.log(res);
  }

  function chooseDate(str, value) {
    console.log(str, value);
    var new_date = {
      end: dates.end,
      start: dates.start,
    };

    if (modo === "inicio") {
      if (str === "mes") {
        new_date.start.mes = value;
      }

      if (str === "ano") {
        new_date.start.ano = value;
      }
    }

    if (modo === "fim") {
      if (str === "mes") {
        new_date.end.mes = value;
      }

      if (str === "ano") {
        new_date.end.ano = value;
      }
    }

    setDates(new_date);
  }

  function returnDate(date) {
    var mes = date.mes;
    var ano = date.ano;
    var nome = meses.find((obj) => obj.month === mes).str;

    return `${nome} ${ano}`;
  }

  function startSearch() {
    function trigger() {
      axios
        .post(`${uri}/tasks`, {
          projeto,
          task: "google_xml",
          files: [],
          goal: 100,
          options: {
            accessToken: infos.accessToken,
            inicio: new Date(dates.start.ano, dates.start.mes),
            fim: new Date(dates.end.ano, dates.end.mes),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
        });
    }

    alerta(
      "Tem certeza que deseja iniciar a varredura de XML's na sua conta Gmail?",
      true,
      "err",
      trigger
    );
  }

  return (
    <Container id="gmail-tools">
      <Block>
        <div className="header">
          <div className="closx" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Capturar NFe's do Gmail</p>
          </div>
        </div>
        {logged ? (
          <>
            <Content>
              <Dates>
                <div
                  onClick={() => setModo("inicio")}
                  className={`date left ${modo === "inicio" && "ativ"}`}
                >
                  <div className="ico">
                    <BsCalendar3Week />
                  </div>
                  <div className="text">
                    <p>Início</p>
                    <p className="data">{returnDate(dates.start)}</p>
                  </div>
                </div>

                <div
                  onClick={() => setModo("fim")}
                  className={`date ${modo === "fim" && "ativ"}`}
                >
                  <div className="ico">
                    <BsCalendar3Week />
                  </div>
                  <div className="text">
                    <p>Fim</p>
                    <p className="data">{returnDate(dates.end)}</p>
                  </div>
                </div>
              </Dates>
              {modo === "inicio" && (
                <DatePicker>
                  <div className="dates">
                    {anos.map((ano) => (
                      <div
                        className={`date year ${
                          ano === dates.start.ano && "ativ"
                        }`}
                        onClick={() => chooseDate("ano", ano)}
                      >
                        <p>{ano}</p>
                      </div>
                    ))}
                  </div>
                  <div className="dates">
                    {meses.map((mes) => (
                      <div
                        className={`date ${
                          mes.month === dates.start.mes && "ativ"
                        }`}
                        onClick={() => chooseDate("mes", mes.month)}
                      >
                        <p>{mes.str}</p>
                      </div>
                    ))}
                  </div>
                </DatePicker>
              )}
              {modo === "fim" && (
                <DatePicker>
                  <div className="dates">
                    {anos.map((ano) => (
                      <div
                        className={`date year ${
                          ano === dates.end.ano && "ativ"
                        }`}
                        onClick={() => chooseDate("ano", ano)}
                      >
                        <p>{ano}</p>
                      </div>
                    ))}
                  </div>
                  <div className="dates">
                    {meses.map((mes) => (
                      <div
                        className={`date ${
                          mes.month === dates.end.mes && "ativ"
                        }`}
                        onClick={() => chooseDate("mes", mes.month)}
                      >
                        <p>{mes.str}</p>
                      </div>
                    ))}
                  </div>
                </DatePicker>
              )}
            </Content>
            <Send>
              <div className="btn-box">
                <div className={`btn`} onClick={startSearch}>
                  <p>INICIAR</p>
                </div>
              </div>
            </Send>
          </>
        ) : (
          <>
            <Content>
              <p className="texto">
                Esta ferramenta faz uma varredura no e-mail do usuário em busca
                de XML's recebidos com o intuíto de fazer o download destes e
                inserí-los no banco de dados do software de acordo com as
                informações dos SPED's carregados no aplicativo. Para utilizar a
                ferramenta é necessário que o e-mail de recebimento dos XML's
                seja fornecido pelo Gmail, e que o usuário sincronize sua conta
                Google com o Ontax.
              </p>
            </Content>
            <Send>
              <div className="btn-box">
                <GoogleLogin
                  clientId={process.env.REACT_APP_CLIENT_ID}
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                  render={(renderProps) => (
                    <div className="btn-google" onClick={renderProps.onClick}>
                      <div className="thumb">
                        <img src={google} />
                      </div>
                      <div className="text">
                        <p>Sincronizar</p>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Send>
          </>
        )}
      </Block>
    </Container>
  );
}

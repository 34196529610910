import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 31;
  
  .speds {
    width: 50em;
    height: 45em;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
    background-color: rgba(230, 230, 230, 1);

    .header {
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      background-color: rgba(245, 245, 245, 0.8);

      .search {
        display: flex;
        width: calc(100% - 2em);
        height: 100%;
        align-items: center;

        svg {
          color: black;
          position: absolute;
          transform: translateX(.8em);
          font-size: .8em;
          opacity: .5;
        }
      }

      .btn {
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba(0, 0, 0, 0.03);
        opacity: .5;
        cursor: pointer;
        transition: all .2s;
        &:hover {
          opacity: 1;
        }
        svg {
          color: black;
          font-size: .8em;
          opacity: .5;
        }
      }


      input {
        width: 40em;
        height: 2em;
        margin-left: .2em !important;
        outline: none;
        border: none;
        border-radius: 5px;
        padding-left: 2em;
        background-color: white;
        color: black;
      }
    }

    .display {
      width: 100%;
      height: calc(100% - 2em);
      overflow: overlay;
      overflow-x: hidden;

      .item {
        margin-top: 1px;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 200, 250, 0);
        width: calc(100% - 20px);
        height: max-content;
        background-color: white;
        flex-wrap: wrap;
        opacity: 0.8;
        color: black;
        cursor: pointer;
        display: flex;

        &:hover {
          opacity: 1;
          border-bottom: 1px solid rgba(0, 200, 250, 0.4);
        }

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: max-content;
          overflow: hidden;
          padding:0 5px !important;
          


          &.query {
            color: rgba(0, 130, 150, 1);
          }
        }
      }
    }
  }
`;


import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

const uri = process.env.REACT_APP_API_URI;

export default function useSpeds(query, page, hook) {
  const { projeto, sped } = hook;
  const [loading, setLoading] = useState(false);
  const [speds, setSpeds] = useState([]);

  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (projeto !== undefined) {
      setLoading(true);
      let cancel;
      axios({
        method: "POST",
        url: `${uri}/speds/${projeto}/${
          sped._id
        }/data?page=${page}&limit=${20}`,
        data: { query },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setSpeds(speds.concat(res.data.data));
          setHasMore(res.data.data.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
        });
      return () => cancel();
    }
  }, [query, page, projeto]);

  return { loading, speds, hasMore, setSpeds };
}

import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container } from './styles.js'
import useSpeds from '../../../../../hooks/spedsData'
import ContentLoader, { Facebook } from 'react-content-loader'
import { useProjetos } from '../../../../../hooks/projetos.js'
import { MdArrowBackIos, MdSearch } from 'react-icons/md'
import { BsSearch } from 'react-icons/bs'

export default function Uploaded() {
  const { sped, projeto, setStatus, setSped, status } = useProjetos()
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)


  const { speds, loading, hasMore, setSpeds } = useSpeds(query, page, useProjetos());
  const observer = useRef()

  const last_element = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  function handleSearch(e) {
    setSpeds([])
    setQuery(e.target.value)
    setPage(1)
  }

  function random() {
    return Math.random() * (350 - 200) + 200
  }

  function LoadItem() {
    return (
      <div className="item">
        <ContentLoader viewBox="0 0 380 25">
          {/* Only SVG shapes */}
          <rect x="10" y="6" rx="0" ry="0" width={random()} height="5" />
          <rect x="10" y="16" rx="0" ry="0" width={random()} height="5" />
        </ContentLoader>
      </div>
    )
  }

  function LoadPanel() {
    const times = 13
    const renders = []

    for (var i = 0; i < times; i++) {
      renders.push('')
    }

    return renders.map((obj) => <LoadItem key={renders.indexOf(obj)} />);
  }

  function close() {
    setStatus()
    setSped()
  }

  function checkQuery(str) {
    if (query !== '' && query.length > 3 && str.toLowerCase().includes(query.toLowerCase())) return 'query'
  }

  if (sped !== undefined && status === 'sped')
    return (
      <Container>
        <div className="speds">
          <div className="header">
            <div className="btn" onClick={close}>
              <MdArrowBackIos />
            </div>
            <div className="search">
              <BsSearch />
              <input
                autocomplete="off"
                placeholder="Digite aqui a informação que deseja buscar"
                id="search"
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="display">
            {speds.length === 0 ? (
              <LoadPanel />
            ) : (
              speds.map((sped, index) => {
                if (speds.length === index + 1) {
                  return (
                    <div ref={last_element} className="item">
                      <p>{sped.replaceAll('|', ' | ')}</p>
                    </div>
                  )
                } else {
                  return (
                    <div className="item">
                      {sped
                        .split('|')
                        .map(
                          obj =>
                            obj !== '' && (
                              <p className={checkQuery(obj)}>{`| ${obj}`}</p>
                            )
                        )}
                    </div>
                  )
                }
              })
            )}
            {hasMore && (
              <>
                <LoadItem />
                <LoadItem />
                <LoadItem />
              </>
            )}
          </div>
        </div>
      </Container>
    )
  else return <></>
}

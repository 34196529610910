import styled, { css } from 'styled-components'



export const Filter = styled.div`
  margin-top: 1.7em;
  background-color: rgba(245, 245, 245, 0.7);
  width: 20em;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: translateX(26em);
  transition: all 0.2s;
  position: fixed;
  z-index: 3;
  right: 0;

  .header {
    width: 100%;
    height: 1.5em;
    display: flex;

    .btn {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      svg {
        font-size: 0.6em;
      }
    }
  }
  &.ativ {
    transform: translateX(0em);
  }

  .dater {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);

    .dates {
      width: 100%;
      height: 5em;
      display: flex;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;

      .date {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        transition: opacity 0.2s;
        outline: none;

        &.ativ {
          opacity: 1;
          border-top: 1px solid rgba(0, 200, 250, 0.3);
          border-right: 1px solid rgba(0, 200, 250, 0.3);
          border-left: 1px solid rgba(0, 200, 250, 0.3);
          background-color: white;
          transform: translateY(1px);
        }

        &:hover {
          opacity: 1;
        }

        &.bar {
          border-right: 1px solid rgba(0, 0, 0, 0.03);
        }

        .content {
          width: 8em;
          height: 3.5em;
          display: flex;
          align-items: center;

          img {
            width: 1em;
            opacity: 0.5;
            margin-right: 1em !important;
          }

          .infos {
            width: max-content;
            height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info {
              font-size: 0.7em;
              height: 1.4em;
            }

            .data {
              font-size: 0.8em;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .date-picker {
    width: 100%;
    height: max-content;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    border: 1px solid rgba(0, 200, 250, 0.3);

    .date-line {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1px;

      background-color: rgba(0, 0, 0, 0.1);
      .date-item {
        width: calc(100% / 6 - 1px);
        height: 2em;
        display: flex;
        background-color: white;
        border-left: 1px solid rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        cursor: pointer;
        &:hover,
        &.ativ {
          opacity: 1;
        }

        p {
          font-size: 0.8em;
          opacity: 0.8;
        }
      }
    }
  }

  .search-bar {
    width: 100%;
    height: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 1px solid rgba(0, 0, 0, 0.03);

    input {
      width: calc(100% - 2em);
      border-radius: 0px;
      height: 100%;
      padding: 0em;
      background-color: rgba(255, 255, 255, 1);
      color: black;
    }

    .btn {
      width: 2.2em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255, 255, 255);

      svg {
        font-size: 0.9em;
        opacity: 0.4;
      }
    }
  }

  .display {
    width: 100%;
    height: calc(100% - 20em);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5em !important;
    overflow: overlay;

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    .sped-item {
      width: 98%;
      min-height: 7em;
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255);
      transition: all 0.1s;

      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      .content {
        width: 85%;
        height: 84%;
        display: flex;
        flex-direction: column;
      }

      .header {
        width: 100%;
        height: 2.5em;
        padding-top: 10px;
        display: flex;
        align-items: center;

        padding-bottom: 10px;

        .name {
          transform: translateY(0.2em);
          display: flex;
          flex-direction: column;
          margin-left: 0.8em;

          .texto {
            font-size: 0.9em;
            font-weight: 500;
          }

          .cnpj {
            font-size: 0.8em;
            font-weight: 500;
            opacity: 0.5;
            transform: translateY(-0.5em);
          }
        }

        .thumb {
          height: 2em;
          width: 2em;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          h2 {
            color: white;
            font-size: 1em;
            font-weight: 500;
          }
        }
      }
    }
  }

  .infos-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.1em !important;

    .box {
      width: calc(100% - 1em);
      height: max-content;

      display: flex;
      flex-direction: column;
      width: calc(100% -1em);
      margin-left: 1em !important;

      .tag {
        width: 100%;
        height: 1.5em;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        align-items: center;
        background-color: rgba(255, 255, 255, 1);
        padding-left: 1em !important;
        transform: translateX(-1em);

        p {
          font-size: 0.7em;
          opacity: 0.5;
        }
      }

      .text {
        width: 100%;
        height: max-content;
        padding: 10px 0 !important;
        display: flex;
        align-items: center;

        &.desc {
          padding: 15px 0 !important;
        }

        p {
          font-size: 0.8em;
          width: 95%;
          line-height: 17px;
          opacity: 0.8;
        }
      }

      .infos {
        width: 100%;
        height: max-content;
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        display: flex;
        background-color: rgba(255, 255, 255, 0.5);
        padding-left: 1em !important;
        transform: translateX(-1em);

        .info {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.5em;
            opacity: 0.5;
          }

          .name {
            font-size: 0.7em;
            opacity: 0.5;
            margin-right: 0.7em !important;
          }

          .number {
            font-size: 0.8em;
            opacity: 0.8;
          }
        }
      }
    }
  }
`;
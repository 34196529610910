import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 31;
  overflow: hidden;
`;

export const Block = styled.div`
  width: 25em;
  height: 30em;
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 240, 240);
  border-radius: 5px;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;

  .close {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .view {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    opacity: 0.5;

    svg {
      opacity: 0.7;
    }

    &:hover {
      opacity: 1;
    }
  }

  .text {
    width: calc(100% - 2em);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 0.9em;
      font-weight: 600;
      opacity: 0.7;
      transform: translateX(-1em);
    }
  }
`;

export const Display = styled.div`
  width: 100%;
  height: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  overflow: overlay;

  .item {
    width: 100%;
    height: max-content;
    display: flex;
    background-color: white;
    flex-direction: column;
    margin-top: 1px !important;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .text {
      width: calc(100% - 0.5em);
      height: max-content;
      display: flex;

      .thumb {
        height: 100%;
        width: 0.3em;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0.7;
      }

      .content {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.5em !important;

        .text-container {
          width: 100%;
          height: max-content;
          display: flex;

          .copy-proj {
            width: 2em;
            height: 3em;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(0, 0, 0, 0.3);
            opacity: 0.5;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }

        .text-content {
          width: 100%;
          height: 3em;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 0.8em;
            line-height: 16px;

            &.cnpj {
              font-size: 0.75em;
              opacity: 0.7;
            }
          }
        }

        .infos {
          width: 100%;
          height: 1.3em;
          display: flex;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          background-color: rgba(250, 250, 250);

          .info {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: max-content;
            padding: 0 10px !important;

            p {
              font-size: 0.8em;
              opacity: 0.8;
            }

            svg {
              font-size: 0.8em;
              margin-right: 0.5em !important;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
`;

import styled from "styled-components";



export const InfoBlock = styled.div`
  width: 1em;
  height: 1em;
  display: flex;

  cursor: pointer;
  .btn-info {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.7em;
      opacity: 1;
      color: rgba(0, 100, 255, 0.3);
    }

    &:hover {
      opacity:.5;
    }
  }
  &:hover {
    .info-text {
      display: flex;
    }
  }

  .info-text {
    position: absolute;
    z-index: 3;
    min-width: 5em;
    min-height: 2em;
    width: max-content;
    height: max-content;
    transform: translateX(1em) translateY(-0.5em);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
    align-items: center;
    background-color: white;

    P {
      padding: 10px 0px;
      max-width: 20em;
      line-height: 15px;
      opacity: 0.5;
    }
  }
`;

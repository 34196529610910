/* eslint-disable no-undef */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthContext from "./hooks/auth";
import ProjetoContext from "./hooks/projetos";
import UploadContext from "./hooks/upload";
import ICMSContext from "./hooks/icms";
import Rotas from "./routes";
import axios from "axios";

axios.interceptors.request.use((request) => {
  request.headers.user = `${localStorage.getItem("user_id")}`;
  request.headers.app = "Manager";
  return request;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContext>
      <ICMSContext>
        <UploadContext>
          <ProjetoContext>
            <Rotas />
          </ProjetoContext>
        </UploadContext>
      </ICMSContext>
    </AuthContext>
  </React.StrictMode>,
  document.getElementById("root")
);

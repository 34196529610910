import styled from 'styled-components'

export const Tela = styled.div`
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 3;
  opacity: 0;
  overflow: hidden;
  transition: all 0.2s;
  color: black;
  &.ativ {
    display: flex;
  }
`

export const Card = styled.div`
  width: 35em;
  margin: 0 1em !important;
  height: 40em;
  background-color: rgba(255, 255, 255);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.2s;
  display: flex;

  &.ativ {
    opacity: 1;
    width: 60em;
    height: 45em;

    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  }
`

export const Menu = styled.div`
  width: 18em;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(237, 236, 233);
`

export const TopBtn = styled.div`
  width: 100%;
  height: 2.5em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;

  .close-btn {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  p {
    margin-left: 1em !important;
    font-weight: 600;
    font-size: 0.7em;
    opacity: 0.6;
  }
`

export const Btn = styled.div`
  width: 100%;
  height: 2em;

  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin-left: 0.5em !important;
    font-size: 0.8em;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.ativ {
    background-color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }
`

export const Thumb = styled.div`
  width: 1.2em;
  height: 1.2em;
  border-radius: 100%;
  background-color: rgba(250, 100, 0, 1);
  margin-left: 1em !important;
  display: flex;
  align-self: center;
  justify-content: center;
  color: white;
  p {
    font-size: 0.8em;
    font-weight: 300;
    margin-left: 0 !important;
    transform: translateY(-0.2em);
  }
`

export const Display = styled.div`
  width: calc(100% - 18em);
  height: 100%;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .padd {
    margin: 10px 0 !important;
  }

  .bottom {
    margin-bottom: 5px !important;
  }

  .bottom-2x {
    margin-bottom: 5px !important;
  }

  .title {
    font-weight: 500;
  }

  p {
    font-size: 0.9em;
  }
  h2 {
    margin-top: 2.5em !important;
    font-size: 1em;
    opacity: 0.8;
    font-weight: 600;
  }

  p.info {
    height: max-content;
    display: flex;
    align-items: center;
    font-style: italic;
    font-size: 0.85em;
    opacity: 0.7;
    svg {
      opacity: 0.8;
      margin-right: 0.5em !important;
    }

    &.small {
      font-size: 0.7em !important;
    }
  }

  input {
    width: 20em;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    color: black;

    &:focus {
      border: 1px solid rgba(0, 200, 250, 0.8);
      box-shadow: 0px 0px 5px rgba(0, 200, 250, 0.3);
    }
  }
`

export const ContentDisplay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.2s;

  &.ativ {
    opacity: 1;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 0.05em;
  background-color: black;
  opacity: 0.1;
  margin-top: 10px !important;
`

export const ThumbPhoto = styled.div`
  width: 5.5em;
  height: 5.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 100, 0, 1);
  border-radius: 100%;
  margin-top: 10px !important;
  margin-bottom: 0.5em !important;
  h1 {
    transform: translateY(-0.1em);
    color: white;
    font-size: 2.4em;
    font-weight: 500;
  }
`

export const EditBtn = styled.div`
  width: max-content;
  height: 2em;
  font-size: 0.9em;
  margin-top: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    border: 1px solid rgba(0, 200, 250, 0.3);
    p {
      opacity: 0.7;
    }
  }
  p {
    color: black;
    padding: 5px 20px;
  }
`

export default function getDates() {
  function getYears() {
    const years = [];
    const now_year = new Date().getFullYear();
    var x = now_year;
    while (x !== now_year - 12) {
      years.push(x);
      x--;
    }

    return years;
  }

  function getMonths() {
    return [
      { month: 0, name: "Janeiro", str: 'Jan' },
      { month: 1, name: "Fevereiro", str: 'Fev' },
      { month: 2, name: "Maço", str: 'Mar' },
      { month: 3, name: "Abril", str:'Abr' },
      { month: 4, name: "Maio", str: 'Mai' },
      { month: 5, name: "Junho", str: 'Jun' },
      { month: 6, name: "Julho", str: 'Jul' },
      { month: 7, name: "Agosto", str: 'Ago' },
      { month: 8, name: "Setembro", str: 'Set' },
      { month: 9, name: "Outubro", str: 'Out' },
      { month: 10, name: "Novembro", str:'Nov' },
      { month: 11, name: "Dezembro", str: 'Dez' },
    ];
  }

  return { years: getYears(), months: getMonths() };
};

import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import reduce from "../utils/reduce";
import alerta from "../utils/alerta";

import { useAuth } from "./auth";

const ProjetoContext = createContext();
const uri = process.env.REACT_APP_API_URI;
export default function ProjetoProvider({ children }) {
  const { user } = useAuth();
  const [status, setStatus] = useState("upload");
  const [projetos, setProjetos] = useState([]);
  const [projeto, setProjeto] = useState();
  const [speds, setSpeds] = useState([]);
  const [sped, setSped] = useState();
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [cnpj, setCnpj] = useState();
  const [infos, setInfos] = useState();
  const [projeto_infos, setProjInfo] = useState();

  async function getEmpresas(clear) {
    if (clear) {
      setEmpresas([]);
    }
    if (projeto !== undefined) {
      await axios({
        method: "GET",
        url: `${uri}/empresas/${projeto}`,
      }).then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          setTimeout(() => {
            const data = res.data;
            setEmpresas(data.data);
            setInfos(data.infos);
            setLoading(false);
          }, [2000]);
        }
      });
    }
  }

  async function getSpeds() {
    if (projeto !== undefined) {
      axios({
        method: "GET",
        url: `${uri}/speds/${projeto}?page=${page}&limit=${20}`,
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            const data = res.data.data;
            setSpeds(data);
            setLoading(false);
          }, [2000]);
        }
      });
    }
  }

  async function getSped(sped) {
    if (sped !== undefined) {
      setStatus("sped");
      axios.get(`${uri}/speds/${projeto}/${sped._id}`).then((res) => {
        if (res.status === 200) {
          setSped(res.data);
        }
      });
    }
  }

  async function deleteSpeds(str) {
    setSpeds([]);
    setLoading(true);

    if (str === "all") {
      axios.delete(`${uri}/speds/${projeto}/all`).then((res) => {
        if (res.status === 200) {
          getSpeds();
          alerta("SPEDS deletados com sucesso!", false, "sucess");
        } else {
          alerta(res.data.msg, false, "err");
        }
      });
    }
  }

  async function getProjetos() {
    setProjetos([]);
    axios.post(`${uri}/projetos/all`, { user }).then((res) => {
      if (res.status === 200) {
        setProjetos(res.data);
      }
    });
  }

  async function novoProjeto(query) {
    axios.post(`${uri}/projetos`, query).then((res) => {
      if (res.status === 200) {
        alerta(res.data.msg, false, "success");
        getProjetos();
      } else {
        alerta(res.data.msg, false, "err");
      }
    });
  }

  async function deleteProjeto(user, projeto) {
    axios.post(`${uri}/projetos/delete`, { user, projeto }).then((res) => {
      if (res.status === 200) {
        alerta(res.data.msg, false, "success");
        getProjetos();
      }

      if (res.status === 400) {
        alerta(res.data.msg, false, "err");
        getProjetos();
      }
    });
  }

  async function getProjeto() {
    if (projeto !== undefined) {
      axios.get(`${uri}/projeto/find/${projeto}`).then((res) => {
        setProjInfo(res.data);
      });
    }
  }

  useEffect(() => {
    axios.get(`${uri}/user/${localStorage.getItem("user_id")}`).then((res) => {
      setProjeto(res.data);
      localStorage.setItem("projeto", res.data.projeto);
    });
  }, []);

  useEffect(() => {
    if (user !== undefined) {
      getProjetos();
    }
  }, [user]);

  useEffect(() => {
    getSpeds();
    setLoading(true);
    getEmpresas(true);
    getProjeto();
  }, [projeto]);

  return (
    <ProjetoContext.Provider
      value={{
        projeto,
        setProjeto,
        projetos,
        setProjetos,
        getProjetos,
        speds,
        setSpeds,
        empresas,
        setEmpresas,
        loading,
        setLoading,
        status,
        setStatus,
        getSpeds,
        page,
        setPage,
        sped,
        setSped,
        getSped,
        deleteSpeds,
        novoProjeto,
        cnpj,
        setCnpj,
        getEmpresas,
        deleteProjeto,
        infos,
        setInfos,
        projeto_infos,
        setProjInfo
      }}
    >
      {children}
    </ProjetoContext.Provider>
  );
}

export function useProjetos() {
  const context = useContext(ProjetoContext);
  const {
    setProjeto,
    projetos,
    setProjetos,
    getProjetos,
    projeto,
    speds,
    setSpeds,
    empresas,
    setEmpresas,
    loading,
    setLoading,
    status,
    setStatus,
    getSpeds,
    page,
    setPage,
    sped,
    setSped,
    getSped,
    deleteSpeds,
    novoProjeto,
    cnpj,
    setCnpj,
    getEmpresas,
    deleteProjeto,
    infos,
    setInfos,
    projeto_infos,
    setProjInfo
  } = context;
  return {
    projeto,
    setProjeto,
    projetos,
    setProjetos,
    getProjetos,
    speds,
    setSpeds,
    empresas,
    setEmpresas,
    loading,
    setLoading,
    status,
    setStatus,
    getSpeds,
    page,
    setPage,
    sped,
    setSped,
    getSped,
    deleteSpeds,
    novoProjeto,
    cnpj,
    setCnpj,
    getEmpresas,
    deleteProjeto,
    infos,
    setInfos,
    projeto_infos,
    setProjInfo
  };
}

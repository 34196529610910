import { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

import {
  ToolsContainer,
  Block,
  Dates,
  DatePicker,
  Empresas,
  Send,
} from "./styles";

import { useProjetos } from "../../../../../hooks/projetos";
import getDates from "../../../../../utils/getDates";
import FileDownload from "js-file-download";

import {
  BsCalendarDate,
  BsCalendar4,
  BsCalendarCheck,
  BsSearch,
  BsFillPlayFill,
  BsDownload,
  BsCalendar3Week,
} from "react-icons/bs";

import { FaRegAddressCard } from "react-icons/fa";

import { MdClose, MdCheck } from "react-icons/md";
import { IoCubeOutline, IoDocumentTextOutline } from "react-icons/io5";

import { HiOutlineSearch } from "react-icons/hi";

import alerta from "../../../../../utils/alerta";
import Loading from "../../../../../utils/loading";
import timer from "../../../../../utils/timer";

const uri = process.env.REACT_APP_API_URI;

export default function Tools() {
  const { projeto, setStatus, empresas, status } = useProjetos();
  const [load, setLoading] = useState(true);
  const [dates, setDates] = useState();
  const [cnpjs, setCnpjs] = useState([]);
  const [files, setFiles] = useState([]);
  const [atualize, setAtualize] = useState(true);
  const [download, setDownload] = useState(false);
  const [modo, setModo] = useState("empresas");
  const [init, setInit] = useState(true);

  const anos = getDates().years;
  const meses = getDates().months;

  function handleClose() {
    document.getElementById("tools").style.display = "none";
  }

  function returnDate(date) {
    var mes = date.mes;
    var ano = date.ano;
    var nome = meses.find((obj) => obj.month === mes).str;

    return `${nome} ${ano}`;
  }

  function chooseDate(str, value) {
    setAtualize(true);
    console.log(str, value);
    var new_date = {
      end: dates.end,
      start: dates.start,
    };

    if (modo === "inicio") {
      if (str === "mes") {
        new_date.start.mes = value;
      }

      if (str === "ano") {
        new_date.start.ano = value;
      }
    }

    if (modo === "fim") {
      if (str === "mes") {
        new_date.end.mes = value;
      }

      if (str === "ano") {
        new_date.end.ano = value;
      }
    }

    setDates(new_date);
  }

  function handleFilter() {
    axios
      .post(`${uri}/tools/filter/${projeto}`, {
        periodo: {
          start: new Date(dates.start.ano, dates.start.mes),
          end: new Date(dates.end.ano, dates.end.mes),
        },
        cnpjs,
      })
      .then((res) => {
        if (res.status === 200) {
          setFiles(res.data);
          setTimeout(() => {
            setAtualize(false);
          }, 1000);
        }
      });
  }

  function chooseCnpj(obj) {
    setAtualize(true);
    var new_cnpjs = cnpjs;
    if (cnpjs.includes(obj)) {
      new_cnpjs = new_cnpjs.filter((cnpj) => cnpj !== obj);
    } else {
      new_cnpjs = new_cnpjs.concat(obj);
    }

    setCnpjs(new_cnpjs);
  }

  function checkClass(cnpj) {
    if (cnpjs.includes(cnpj)) {
      return "ativ";
    }
  }

  function startCheck() {
    function sendTrigger() {
      const task = {
        projeto,
        task: "atualize_check",
        files,
      };

      axios.post(`${uri}/tasks`, task).then((resp) => {
        if (resp.status === 200) {
          window.location.reload();
        }
      });
    }

    alerta(
      "Tem certeza que deseja atualizar o checklist a partir do filtro selecionado?",
      true,
      "err",
      sendTrigger
    );
  }

  useEffect(() => {
    if (projeto !== undefined) {
      axios.post(`${uri}/tools/${projeto}`).then((res) => {
        if (res.status === 200) {
          setCnpjs(res.data.cnpjs);
          setFiles(res.data.arquivos);
          setDates(res.data.periodo);
          setDownload(res.data.check_download);

          var start = res.data.periodo.start;
          var end = res.data.periodo.end;

          setDates({
            start: {
              ano: new Date(start).getFullYear(),
              mes: new Date(start).getMonth(),
            },
            end: {
              ano: new Date(end).getFullYear(),
              mes: new Date(end).getMonth(),
            },
          });

          console.log({
            start: {
              ano: new Date(start).getFullYear(),
              mes: new Date(start).getMonth(),
            },
            end: {
              ano: new Date(end).getFullYear(),
              mes: new Date(end).getMonth(),
            },
          });

          res.data.cnpjs.map((obj) => {
            try {
              document.getElementById(`item-${obj}`).checked = true;
            } catch (e) {}
          });

          setAtualize(false);
          setInit(false);
          setLoading(false);
        }
      });
    }
  }, [projeto]);

  useEffect( () => {
    (async () => {
      if (atualize === true && init === false) {
        handleFilter();
      }
    })()
  }, [cnpjs, dates]);



  return (
    <ToolsContainer id="tools">
      <Block>
        <div className="header">
          <div className="closx" onClick={handleClose}>
            <MdClose />
          </div>
          {!load && (
            <div className="infos">
              <div className="info">
                <IoDocumentTextOutline className={atualize && "ativ"} />
                {atualize ? Loading(40) : <p>{files.length}</p>}
              </div>
              <div className="info">
                <FaRegAddressCard />
                <p>{cnpjs.length}</p>
              </div>
            </div>
          )}
        </div>
        {!load && (
          <>
            {" "}
            <Dates>
              <div
                onClick={() => setModo("inicio")}
                className={`date left ${modo === "inicio" && "ativ"}`}
              >
                <div className="ico">
                  <BsCalendar3Week />
                </div>
                <div className="text">
                  <p>Início</p>
                  <p className="data">{returnDate(dates.start)}</p>
                </div>
              </div>

              <div
                onClick={() => setModo("fim")}
                className={`date ${modo === "fim" && "ativ"}`}
              >
                <div className="ico">
                  <BsCalendar3Week />
                </div>
                <div className="text">
                  <p>Fim</p>
                  <p className="data">{returnDate(dates.end)}</p>
                </div>
              </div>
            </Dates>
            {modo === "inicio" && (
              <DatePicker>
                <div className="dates">
                  {anos.map((ano) => (
                    <div
                      className={`date year ${
                        ano === dates.start.ano && "ativ"
                      }`}
                      onClick={() => chooseDate("ano", ano)}
                    >
                      <p>{ano}</p>
                    </div>
                  ))}
                </div>
                <div className="dates">
                  {meses.map((mes) => (
                    <div
                      className={`date ${
                        mes.month === dates.start.mes && "ativ"
                      }`}
                      onClick={() => chooseDate("mes", mes.month)}
                    >
                      <p>{mes.str}</p>
                    </div>
                  ))}
                </div>
              </DatePicker>
            )}
            {modo === "fim" && (
              <DatePicker>
                <div className="dates">
                  {anos.map((ano) => (
                    <div
                      className={`date year ${ano === dates.end.ano && "ativ"}`}
                      onClick={() => chooseDate("ano", ano)}
                    >
                      <p>{ano}</p>
                    </div>
                  ))}
                </div>
                <div className="dates">
                  {meses.map((mes) => (
                    <div
                      className={`date ${
                        mes.month === dates.end.mes && "ativ"
                      }`}
                      onClick={() => chooseDate("mes", mes.month)}
                    >
                      <p>{mes.str}</p>
                    </div>
                  ))}
                </div>
              </DatePicker>
            )}
            <Empresas
              onClick={() => setModo("empresas")}
              className={modo === "empresas" && "ativ"}
            >
              <div className="search">
                <div className="ico">
                  <HiOutlineSearch />
                </div>
                <input placeholder="Digite o nome da empresa" />
              </div>
              <div className="display">
                {empresas.length > 0 &&
                  empresas.map((obj) => (
                    <div
                      onClick={() => chooseCnpj(obj.cnpj)}
                      className={`empresa ${checkClass(obj.cnpj)}`}
                    >
                      <div className="ico">
                        {cnpjs.includes(obj.cnpj) ? (
                          <MdCheck color="rgba(0, 150, 0)" />
                        ) : (
                          <IoCubeOutline color={obj.color} />
                        )}
                      </div>
                      <div className="text">
                        <p>{obj.nome}</p>
                        <p className="cnpj">{obj.cnpj}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Empresas>
          </>
        )}
        <Send>
          <div className="btn-box">
            <div onClick={startCheck} className={`btn ${atualize && "ativ"}`}>
              <p>INICIAR</p>
            </div>
          </div>
        </Send>
      </Block>
    </ToolsContainer>
  );
}

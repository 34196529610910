import React from "react";

import ContentLoader from "react-content-loader";

import { UploadingBox } from "../../styles";

export default function LoadPanel() {
  var x = 0;
  var result = [];

  while (x !== 9) {
    result.push(x);
    x++;
  }

  function LoadItem() {
    return (
      <UploadingBox>
        <div
          className="content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <ContentLoader viewBox="0 0 380 70">
            {/* Only SVG shapes */}
            <rect x="20" y="25" rx="5" ry="5" width="30" height="30" />
            <rect x="80" y="25" rx="4" ry="4" width="340" height="10" />
            <rect x="80" y="45" rx="3" ry="3" width="250" height="10" />
          </ContentLoader>{" "}
        </div>
      </UploadingBox>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      {result.map((obj) => (
        <LoadItem key={result.indexOf(obj)} />
      ))}
    </div>
  );
}

export default function percent(num, total) {
  if(total === 0) {
    return 100
  }
  var perc = total / 100
  if (isNaN(parseFloat(num / perc).toFixed(2))) {
    return 0
  }
  return parseFloat(num / perc).toFixed(2)
}

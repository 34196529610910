import React, { useState, useEffect } from "react";
import { Container, Block, Header, Display } from "./styles";

import axios from "axios";

import { MdClose } from "react-icons/md";
import { BiWindowOpen } from "react-icons/bi";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { FaDatabase } from "react-icons/fa";
import { AiOutlineIdcard } from "react-icons/ai";
import { IoCopyOutline, IoDocumentTextOutline } from "react-icons/io5";
import { useProjetos } from "../../../../../hooks/projetos";
import { useAuth } from "../../../../../hooks/auth";
import { ToastContainer, toast } from "react-toastify";

const uri = process.env.REACT_APP_API_URI;

export default function Projetos() {
  const [projetos, setProjetos] = useState([]);
  const { projeto } = useProjetos();
  const { user } = useAuth();

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    }`;
  }

  function getProjetos() {
    setProjetos([]);
    axios.get(`${uri}/admin/projetos`).then((res) => {
      setProjetos(res.data);
    });
  }

  function handleClose() {
    document.getElementById("projs").style.display = "none";
  }

  async function setProj(obj) {
    axios.post(`${uri}/user/${user}`, { projeto: obj }).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  }

  async function goToAdmin() {
    window.location.href = process.env.REACT_APP_ADMIN_URI;
  }

  useEffect(() => {
    if (localStorage.getItem("classe") === process.env.REACT_APP_ADMIN) {
      getProjetos();
    }
  }, []);

  function copyProj(text) {
    toast.success("Id do projeto copiado");
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  return (
    <Container id="projs">
      <Block>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Header>
          <div className="close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Projetos</p>
          </div>
          <div className="view" onClick={goToAdmin}>
            <BiWindowOpen />
          </div>
        </Header>
        <Display>
          {projetos.map((obj) => (
            <div className="item">
              <div className="text">
                <div
                  className="thumb"
                  style={{ backgroundColor: obj.color }}
                ></div>
                <div className="content">
                  <div className="text-container">
                    <div
                      className="text-content"
                      onClick={() => setProj(obj.data._id)}
                    >
                      <p>{obj.data.nome}</p>
                      <p className="cnpj">{obj.data.cnpj}</p>
                    </div>
                    <div
                      className="copy-proj"
                      onClick={() => copyProj(obj.data._id)}
                    >
                      <IoCopyOutline />
                    </div>
                  </div>
                  <div className="infos">
                    <div className="info">
                      <FaDatabase />
                      <p>{formatBytes(obj.data.size)}</p>
                    </div>
                    <div className="info">
                      <HiOutlineReceiptTax />
                      <p>{obj.data.nfes}</p>
                    </div>
                    <div className="info">
                      <IoDocumentTextOutline />
                      <p>{obj.data.speds}</p>
                    </div>
                    <div className="info">
                      <AiOutlineIdcard />
                      <p>{obj.data.empresas}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Display>
      </Block>
    </Container>
  );
}

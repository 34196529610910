import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

//import ParteI from './components/parte1/index'

import Settings from "./components/dashboard/components/black_screen/settings/";
import Uploaded from "./components/dashboard/components/black_screen/sped/";
import Alerta from "./utils/alerta/index";
import Tools from "./components/dashboard/components/black_screen/tools";
import Log from "./components/login/index";
import Dashboard from "./components/dashboard/index";
import Produtos from "./components/dashboard/components/black_screen/produtos";
import Download from "./components/dashboard/components/black_screen/downloads";
import Gmail from "./components/dashboard/components/black_screen/gmail";
import Speds from "./components/dashboard/components/black_screen/speds";
import { AppContainer, Display } from "./styles";

import { useAuth } from "./hooks/auth";
import { useProjetos } from "./hooks/projetos";
import { Inputs } from "./components/login/styles";
import { useUpload } from "./hooks/upload";

import { gapi } from "gapi-script";
import ICMS from "./components/dashboard/components/black_screen/icms";

const uri = process.env.REACT_APP_API_URI;

console.log(process.env.REACT_APP_CLIENT_ID);

function App() {
  const [electron, setElectron] = useState(false);
  const { login, setLogin, infos } = useAuth();
  const { sped, projeto } = useProjetos();
  const { status } = useUpload();

  const inputs = document.getElementsByTagName("input");
  for (var i = 0; i < inputs.length; i++) {
    inputs[i].setAttribute("autocomplete", "off");
  }

  const token = localStorage.getItem("app-token");

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    console.log("infos");
    console.log(infos);
  }, [infos]);

  return (
    <AppContainer>
      <iframe
        id="free-app"
        src="http://localhost:3001"
        style={{ display: "none" }}
      ></iframe>
      <Gmail />
      <Download />
      <Tools />
      <Produtos />
      <Alerta />
      <Speds />
      <ICMS />
      {login && (
        <>
          {sped !== undefined && <Uploaded />}
          <Settings />
        </>
      )}
      <Display id="display">
        {!login ? (
          <Log />
        ) : infos.classe === process.env.REACT_APP_ADMIN ? (
          <Dashboard />
        ) : (
          <></>
        )}
        {!login ? <Log /> : infos.subclass !== "free" ? <Dashboard /> : <></>}
      </Display>
    </AppContainer>
  );
}

export default App;

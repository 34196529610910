import React from "react";
import Lottie from "react-lottie";
import * as loading from "./loading.json";
import { Load } from "./styles.js";

const defaultOptions = {
  loop: true,
  autoplay: true,
  speed: 3,
  animationData: loading.default,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loading = (size) => {
  return (
    <Load>
      <Lottie options={defaultOptions} height={size === undefined ? 400 : size} width={size === undefined ? 400 : size} />
    </Load>
  );
};

export default Loading;

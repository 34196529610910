import { useState, useEffect } from "react";

import axios from "axios";
import FileDownload from "js-file-download";

import { MdClose } from "react-icons/md";
import { BsFileText, BsDownload } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { VscLibrary } from "react-icons/vsc";

import { Container, Block, Header, Display } from "./styles";

import { useProjetos } from "../../../../../hooks/projetos";

import Loading from "../../../../../utils/loading";
import { useUpload } from "../../../../../hooks/upload";

const uri = process.env.REACT_APP_API_URI;

export default function Download() {
  const { projeto, status: upload_status } = useProjetos();
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("");
  const {tasks, atualize, setAtualize} = useUpload()
  

  const icos = [
    { ico: <SiMicrosoftexcel />, tipo: ".csv" },
    { ico: <BsFileText />, tipo: ".txt" },
    { ico: <VscLibrary />, tipo: ".zip" },
  ];

  function handleClose() {
    document.getElementById("download-tool").style.display = "none";
  }

  function downloadFile(file) {
    setStatus(file.file)
    if (file.check) {
      axios
        .post(
          `${uri}/tools/download/${projeto}`,
          { file: file.file },
          {
            responseType: "blob",
            onDownloadProgress: (e) => {
              var progress = parseInt(Math.round((e.loaded * 100) / e.total));
              document.getElementById(`${`prog-${files.indexOf(file)}`}`).style.width = `${progress}%`;
            }
            
          }
        )
        .then((res) => {
          FileDownload(res.data, file.file_name);
          setStatus('')
        });
    }
  }

  useEffect(() => {
    if (projeto !== undefined && atualize !== false) {
      axios.get(`${uri}/tools/files/${projeto}`).then((res) => {
        if (res.status === 200) {
          setFiles(res.data);
          setAtualize(false)
        }
      });
    }
  }, [projeto, atualize]);



  function Item(obj, arr) {
    return (
      <div className="item-content">
        <div className={`item ${!obj.check && "death"}`}>
          <div className="thumb">
            {icos.find((ico) => ico.tipo === obj.tipo).ico}
          </div>
          <div className="text">
            <p>{obj.text}</p>
          </div>
          <div
            className="thumb download"
            onClick={() => downloadFile(obj, files)}
          >
            {status === obj.file ? Loading(40) : <BsDownload />}
          </div>
        </div>
        {status === obj.file && (
          <div className="progress">
            <div className="load" id={`prog-${files.indexOf(obj)}`}></div>
          </div>
        )}
      </div>
    );
  }
  return (
    <Container id="download-tool">
      <Block>
        <Header>
          <div className="close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text">
            <p>Downloads</p>
          </div>
        </Header>
        <Display>
          {files.length !== 0 && files.map((obj) => Item(obj, files))}
        </Display>
      </Block>
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .head {
    width: 100%;
    height: 1.8em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &.table {
      overflow: hidden;
      flex-wrap: nowrap;
      background-color: rgba(255, 255, 255);
    }

    p {
      font-size: 0.8em;
      opacity: 0.9;
      margin: 0 1.3em !important;
      font-weight: 400;
    }

    &.ico {
      justify-content: space-between;
      p {
        margin: unset !important;
      }

      svg {
        transform: translateX(-10px);

    
      }

      .head-ico {
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px !important;
          margin-right: 5px !important;
          transform: translateX(0);
          font-size: 0.7em;
          opacity: .5;
        }
      }
    }
  }
`;

export const Cards = styled.div`
  width: 100%;
  height: 8em;
  font-size: 1em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  align-items: center;
  overflow: hidden;
  overflow-x: overlay;

  .card {
    width: max-content;
    min-width: 15em;
    height: 5.5em;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0em 1em !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);

    .content {
      width: 100%;
      height: calc(100% - 1.5em);
      display: flex;
      align-items: center;

      h1 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 1.4em;
        margin-left: 0.8em !important;
        margin-right: 2em !important;
      }
    }
  }
`;

export const Grafico = styled.div`
  width: 100%;
  height: 20em;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  align-items: center;
  overflow: hidden;
  overflow-x: overlay;

  .index {
    min-width: 15em;
    max-width: 25em;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 1em !important;

    &.search {
      width: 20em;

      .infos {
        background-color: rgba(250, 250, 250);
        width: 100%;
        height: calc(100% - 1.8em);

        .tag {
          width: 100%;
          height: 1.3em;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.5);
          border-bottom: 1px solid rgba(0, 0, 0, 0.03);

          p {
            transform: translateX(1em);
            font-size: 0.7em;
            opacity: 0.5;
          }
        }

        .text {
          width: 100%;
          height: 2em;
          display: flex;
          align-items: center;
          background-color: white;

          p {
            transform: translateX(1em);
            font-size: 0.7em;
            opacity: 0.8;
          }
        }
      }
    }

    .back-btn {
      width: 1.5em;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 0.8em;
        opacity: 0.5;
      }
    }

    .display {
      width: 100%;
      height: calc(100% - 1.8em);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: overlay;
      background-color: rgba(250, 250, 250);

      .item {
        width: 100%;
        height: 3.5em;
        font-size: 1em;
        display: flex;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        margin-top: 1px !important;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
          border-bottom: 1px solid rgba(0, 200, 255, 0.1);
        }

        .thumb {
          width: 3.5em;
          height: 3.5em;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 0.7em;
          }
        }

        .content {
          width: calc(100% - 2em);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 0.8em;
            font-weight: 400;
            margin-left: 1em !important;
            line-height: 17px;

            &.cnpj {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }

  .grafico {
    min-width: 20em;
    width: 25em;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;

    .charts {
      width: 100%;
      height: calc(100% - 1.8em);
      display: flex;
      background-color: rgba(250, 250, 250);
      overflow: hidden;

      .column {
        width: 3.5em;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-left: 1px solid rgba(0, 0, 0, 0.03);
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1;

        opacity: 0.7;
        cursor: pointer;

        .bar {
          width: 50%;
          height: 33%;
          display: flex;
          background-color: rgba(0, 200, 100);
          justify-content: center;

          .tag {
            width: max-content;
            height: 1.7em;
            z-index: 3;
            display: flex;
            background-color: rgba(10, 10, 10);
            color: white;
            position: fixed;
            transform: translateY(-2.5em);
            border-radius: 5px;
            align-items: center;
            opacity: 0;
            transition: all 0.2s;

            p {
              margin: 0 1em !important;
              font-size: 1em;
              font-weight: 300;
            }
          }
        }

        &:hover {
          opacity: 1;
          background-color: white;
          z-index: 3;

          .tag {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const Tabela = styled.div`
  width: 100%;
  height: calc(100% - 30.4em);
  display: flex;
  flex-direction: column;
  .item {
    width: 15em;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
    flex-wrap: nowrap;
    

    &.cnpj {
      width: 20em;
    }

    p {
      font-size: 0.8em;
      margin-left: 1em !important;
      white-space: nowrap;


    }
  }

  .display {
    width: 100%;
    height: calc(100% - 1.8em);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;

    .items {
      width: 100%;
      height: 2em;
      display: flex;
      background-color: rgba(255, 255, 255);
      align-items: center;
      opacity: 0.6;
      margin-top: 1px !important;
      cursor: pointer;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
        height: calc(2em - 1px);
        border-bottom: 1px solid rgba(0, 200, 255, 0.3);
      }

      .thumb {
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 0.8em;
        }
      }
    }
  }
`;

export const InfoBlock = styled.div`
  width: 1em;
  height: 1em;
  display: flex;

  cursor: pointer;
  .btn-info {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.7em;
      opacity: 0.2;
    }

    &:hover {
      opacity:.5;
    }
  }
  &:hover {
    .info-text {
      display: flex;
    }
  }

  .info-text {
    position: absolute;
    z-index: 3;
    min-width: 5em;
    min-height: 2em;
    width: max-content;
    height: max-content;
    transform: translateX(1em) translateY(-0.5em);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
    align-items: center;
    background-color: white;

    P {
      padding: 10px 0px;
      max-width: 20em;
      line-height: 15px;
      opacity: 0.5;
    }
  }
`;
